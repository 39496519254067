import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
import { WorkorderService } from "../services/workorder.service";
import { SectionArray } from "../workorder-getsave/workorder-getsave.component";
import { KeyValue } from "@angular/common";
import { UtilityService } from "../services/utility.service";

@Component({
  selector: "app-home-audit-measure",
  templateUrl: "./home-audit-measure.component.html",
  styleUrls: ["./home-audit-measure.component.scss"],
})
export class HomeAuditMeasureComponent implements OnInit {
  @Input() response: any = [];
  @Input() workorderResponse;
  @Input() measuresLength = 0;
  @Input() measureResponse;

  @Output() notifyHideMeasures: EventEmitter<any> = new EventEmitter();
  @Output() notifyLoadWorkorder: EventEmitter<any> = new EventEmitter();
  @Output() saveWorkorder: EventEmitter<any> = new EventEmitter();
  //---------Measures-----------
  lineItemId: any;
  ShowAddMeasures = false;
  measureSaving: any = {};
  //----------------------------

  sectionObj: SectionArray[] = [];
  psegdisable = "No";
  sectionEdited: any;

  constructor(
    private workorderService: WorkorderService,
    private spinner: NgxSpinnerService,
    private utilityService: UtilityService
  ) { }

  ngOnInit(): void { }

  //------------------Measures----------------------
  saveMeasure() {
    this.saveWorkorder.emit(this.measureResponse);
  }

  calculateMeasure() {
    this.measureResponse?.DetailedInfo[0]?.Attributes.forEach((element) => {
      if (element.Name == "Energy Savings per unit(KWh)") {
        this.measureSaving.energySelectedValue = element.SelectedValue;
      } else if (element.Name == "Cost per unit($)") {
        this.measureSaving.costSelectedValue = element.SelectedValue;
      } else if (element.Name == "Quantity") {
        this.measureSaving.quantitySelectedValue = element.SelectedValue;
      }
    });

    this.measureResponse?.DetailedInfo[0]?.Attributes.forEach((element) => {
      if (element.Name == "Energy Saving(kWh)") {
        element.SelectedValue = (
          this.measureSaving.energySelectedValue *
          this.measureSaving.quantitySelectedValue
        ).toFixed(3);
      } else if (element.Name == "Cost($)") {
        element.SelectedValue = (
          this.measureSaving.costSelectedValue *
          this.measureSaving.quantitySelectedValue
        ).toFixed(2);
      }
    });
  }

  goToMeasureScroll(id) {
    let el = document.getElementById(id);
    el.scrollIntoView();
  }

  updateMeasure(event, isMeasure, lineID = null) {
    if (!isMeasure) return;
    this.spinner.show();
    this.workorderService
      .addMeasures(
        this.workorderResponse.WorkorderId,
        "addMeasure",
        localStorage.getItem("subGroup"),
        lineID ?? this.lineItemId,
        event,
        localStorage.getItem("subGroup1")
      )
      .subscribe(
        (data: any) => {
          this.measureResponse = data;
          this.measuresLength = data?.DetailedInfo[0]?.Attributes?.length;
          this.lineItemId = data?.DetailedInfo[0]?.Attributes?.filter(
            (x) => x["Name"] == "Record ID"
          )[0]?.SelectedValue;
          this.spinner.hide();
        },
        () => { }
      );
  }

  deleteMeasure(lineItem) {
    this.spinner.show();
    if (lineItem) {
      this.spinner.hide();
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result: any) => {
        // if line item is old then call delete API.
        if (result.value) {
          this.spinner.show();
          this.workorderService
            .deleteMeasure(
              this.workorderResponse?.WorkorderId,
              lineItem,
              localStorage.getItem("subGroup"),
              localStorage.getItem("subGroup1")
            )
            .subscribe(
              (res) => {
                if (!res.Message) {
                  this.spinner.hide();
                  Swal.fire({
                    text: "Successfully Deleted",
                    icon: "success",
                    confirmButtonText: "OK",
                  }).then(() => {
                    this.notifyLoadWorkorder.emit("show");
                    // this.loadDynamicMenu();
                    this.addMeasureClose();
                  });
                } else {
                  this.spinner.hide();
                  Swal.fire({
                    text: res.Message,
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                }
              },
              () => {
                this.spinner.hide();
                Swal.fire({
                  text: "Error While Deleting",
                  icon: "error",
                  confirmButtonText: "OK",
                });
              }
            );
        }
      });
    }
  }

  addMeasureClose() {
    this.ShowAddMeasures = false;
    this.notifyHideMeasures.emit(false);
    this.measuresLength = 0;
    localStorage.removeItem("mData");
  }

  //------------------Measures----------------------

  oninitialize(selectvalue: any, attribute: any, editable: any) {
    if (attribute == "REFOWN1") {
      if (selectvalue == "2" && editable == "Yes") {
        this.psegdisable = "Yes";
      } else if (selectvalue == "1" || editable != "Yes") {
        this.psegdisable = "No";
      }
    }
  }
  dataChanged_customize($event, sectionId, rowId, attribute: any) {
    if (attribute == "REFOWN1") {
      this.onchangedropdown($event);
    }
    this.sectionEdited = true;

    this.sectionObj.push({ sectionId: sectionId, rowId: rowId });
    localStorage.setItem("mData", JSON.stringify(this.measureResponse));
  }
  dataChanged(sectionId, rowId) {
    this.sectionEdited = true;

    this.sectionObj.push({ sectionId: sectionId, rowId: rowId });
    localStorage.setItem("mData", JSON.stringify(this.measureResponse));
  }
  onchangedropdown(value: any) {
    if (value == "2") {
      this.psegdisable = "Yes";
    } else if (value == "1") {
      this.psegdisable = "No";
    }
  }
  psgDataChanged($event, sectionId, rIndex, cIndex, nIndex) {
    let index = nIndex ? nIndex : rIndex;
    this.workorderResponse.SectionInfo.forEach((element) => {
      if (sectionId == element.SectionId) {
        element.IsSectionModified = "true";
        element.Tabular[index].ShowAddMeasures = 1;
        element.Tabular[index].Attributes[cIndex]["SelectedValue"] = $event;
        const selValList =
          element.Tabular[index].Attributes[cIndex]["Values"] || [];
      }
    });
  }

  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };
}
