export const environment = {
  production: false,
  apiUrl:'https://oneringngdevapi.azurewebsites.net/api/',
  apiCode:'8BccMVHBqHfKm2aeD7RnJjilUdTZGgeirJsuugSFqWdtAzFuADU2Aw==',
  dataLoadUrl:'https://alteryx-qa.honeywell.com/gallery/#!app/Master_PSG_DataSource_v1/60250cb36b96771c6ce9ab80',
  adalConfiguration : {
    tenant: '96ece526-9c7d-48b0-8daf-8b93c90a5d18',
    clientId: 'bb37820e-e608-425b-8fc6-d85831843806',
    redirectUri: "https://oneringuidev.utilitywebaccess.com",
    endpoints: {
      'https://oneringngdevapi.azurewebsites.net':'b7d85d83-acf4-4522-a8f5-9252e41cc58a'
    },
    navigateToLoginRequestUrl: true,
    cacheLocation: 'localStorage',
   
  },
  localMode: false,
  versionNumber: "1.2.0",
  imageFileTypes:['jpg','jpeg',"png","bmp","tiff","gif"],
  pdfType:["pdf"],
  docTypes:["doc","docx","docm"],
  excelTypes:["csv","xls","xlsm","xlsx"],
  textTypes:["txt"],
  idleTimeBeforeWarning: 85,    // idle time before dialog is popped in minutes
  idleWarningTime: 300000,      // time dialog is displayed in msec before you are logged out - 10 minutes
  environmentVariable:'OneRing-Dev',

  sentryShowdialog: true,
  sentryEnvironment: 'Dev',
  sentryRelease: '3482',
};
