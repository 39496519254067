<mat-card>
    <mat-card-header>
        <mat-card-title style="font-size: 16px">
            Contractors Data
        </mat-card-title>
        <mat-card-actions>
            <button mat-raised-button color="primary" (click)="ExportTOExcel()" [disabled]="count == 0">
                Export As Excel
            </button>
        </mat-card-actions>
    </mat-card-header>
    <mat-divider></mat-divider>
    <br />
    <mat-card-content>
        <div class="mat-elevation-z8" #TABLE>
            <table mat-table matSort [dataSource]="dataSource">
                <ng-container matColumnDef="OrganizationCode">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Organization Code</th>
                    <td mat-cell *matCellDef="let report">
                        {{ report.OrganizationCode }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="OrganizationName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Organization Name</th>
                    <td mat-cell *matCellDef="let report">
                        {{ report.OrganizationName }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="ContactName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Contact Name</th>
                    <td mat-cell *matCellDef="let report">
                        {{ report.ContactName }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Phone">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
                    <td mat-cell *matCellDef="let report">
                        {{ report.Phone }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="UsersEmail">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                    <td mat-cell *matCellDef="let report">
                        {{ report.UsersEmail }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Address">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
                    <td mat-cell *matCellDef="let report">
                        {{ report.Address }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="ActiveInactive">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Active/Inactive</th>
                    <td mat-cell *matCellDef="let report">
                        {{ report.ActiveInactive }}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <br /><br />
            <mat-paginator [pageSizeOptions]="[10,20,30]" showFirstLastButtons></mat-paginator>
        </div>
        <ng-container *ngIf="count == 0">
            <span style="text-align: center !important; font-size: larger">No records found.</span>
        </ng-container>
    </mat-card-content>
</mat-card>
