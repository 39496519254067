<div id="MeasuresForms">
    <div *ngIf="response.ShowAddMeasures == 1">
        <mat-card class="height-width">
            <mat-card-content>
                <div *ngIf="measuresLength!= 0">
                    <mat-grid-list cols="12" rowHeight="35px" class="gridItems">
                        <ng-container *ngFor="let subResponse of measureResponse?.DetailedInfo[0]?.Attributes">
                            <mat-grid-tile class="work-get-tile"
                                [ngStyle]="{'text-align': subResponse.DataType=='RadioList' ? 'center' : 'inherit' }"
                                colspan="{{subResponse.DataType=='RadioList' ? '12' : '4'}}"
                                *ngIf="subResponse.Visible != '0'">
                                <mat-label *ngIf="subResponse.DataType=='RadioList'" matTooltip="{{subResponse.Name}}"
                                    class="label">{{subResponse.Name}}
                                    <span class="symbolStyle" *ngIf="subResponse.IsMandatory == 1">*</span>
                                </mat-label>
                                <mat-label *ngIf="subResponse.DataType!='RadioList' && subResponse.Name!='Notes'" matTooltip="{{subResponse.Name}}"
                                    class="label">{{subResponse.Name}}
                                    <span class="symbolStyle" *ngIf="subResponse.IsMandatory == 1">*</span>
                                </mat-label>
                                
                                <mat-form-field appearance="outline" class="work-get-form font form-field"
                                    *ngIf="subResponse.DataType=='List'">
                                    <mat-select [(ngModel)]="subResponse.SelectedValue"
                                        (selectionChange)="updateMeasure($event.value, subResponse.Name =='Measure','')"
                                        (ngInit)="oninitialize(subResponse.SelectedValue,subResponse.AttributeId,subResponse.IsEditable)"
                                        (ngModelChange)="dataChanged_customize($event,i,d,subResponse.AttributeId);"
                                        [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                                        [name]="subResponse.AttributeId">
                                        <mat-option *ngFor="let optionValues of subResponse.Values"
                                            [value]="optionValues.Id" style="font-size: 11px;">
                                            {{optionValues.Value}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div appearance="outline" class="font width-20"
                                    *ngIf="subResponse.DataType=='RadioList'">
                                    <mat-radio-group aria-labelledby="work-get-radio-group-label"
                                        class="work-get-radio-group" [(ngModel)]="subResponse.SelectedValue"
                                        (ngModelChange)="dataChanged($event,i,d)" [name]="subResponse.AttributeId">
                                        <mat-radio-button class="work-get-radio-button"
                                            *ngFor="let val of subResponse.Values" [value]="val.Id"
                                            [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                                            [name]="subResponse.AttributeId">
                                            {{val.Value}}</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <mat-form-field appearance="outline" class="font form-field"
                                    *ngIf="subResponse.DataType=='String' && subResponse.Name!='Notes'">
                                    <ng-container *ngIf="subResponse.Size <=50">
                                        <input autocomplete="off" appInputlengthFilter matInput
                                            [(ngModel)]="subResponse.SelectedValue"
                                            (ngModelChange)="dataChanged($event,i,d)" [disabled]="(subResponse.IsEditable == 'Yes' ||
                    (psegdisable == 'Yes' &&
                      response.SectionName == 'Owner Information' )? false : true)"
                                            [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''"
                                            [maxlength]="subResponse.Size" [name]="subResponse.AttributeId">
                                    </ng-container>
                                    <ng-container *ngIf="subResponse.Size >50">
                                        <textarea appInputlengthFilter [(ngModel)]="subResponse.SelectedValue" rows="1"
                                            cols="15" matInput
                                            [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                                            [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''"
                                            [maxlength]="subResponse.Size" (ngModelChange)="dataChanged($event,i,d)"
                                            [name]="subResponse.AttributeId"></textarea>
                                    </ng-container>
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="font form-field"
                                    *ngIf="subResponse.DataType=='Integer'">
                                    <input appInputlengthFilter [(ngModel)]="subResponse.SelectedValue"
                                        pattern="^[1-9][0-9]*$"
                                        oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='1';" matInput
                                        (ngModelChange)="dataChanged($event,i,d)" min="0" appNumeric minlength="0"
                                        [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                                        [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''"
                                        type="number" [max]="subResponse.Max" [name]="subResponse.AttributeId"
                                        [maxlength]="subResponse.Size">
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="font form-field"
                                    *ngIf="subResponse.DataType=='Decimal'">
                                    <input appInputlengthFilter [(ngModel)]="subResponse.SelectedValue"
                                        pattern="^[1-9][0-9]*$"
                                        oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='1';" matInput
                                        [min]="subResponse.Min"
                                        [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''"
                                        (ngModelChange)="dataChanged($event,i,d); calculateMeasure()" appDecimalnumber
                                        minlength="0" [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                                        type="number" [max]="subResponse.Max" [name]="subResponse.AttributeId"
                                        [maxlength]="subResponse.Size">
                                </mat-form-field>

                                <!--------------Savings------------------>
                                <mat-form-field appearance="outline" class="form-field font" *ngIf="subResponse.DataType=='Savings'">
                                    <input appInputlengthFilter [(ngModel)]="subResponse.SelectedValue" matInput appSavingsDecimalnumber
                                        [min]="subResponse.Min" (ngModelChange)="dataChanged($event,i,d);" minlength="0"
                                        [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                                        [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''" type="number"
                                        [max]="subResponse.Max" [name]="subResponse.AttributeId" [maxlength]="subResponse.Size">
                                </mat-form-field>

                                <mat-checkbox class="width-50" *ngIf="subResponse.DataType=='Bit'"
                                    [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                                    [(ngModel)]="subResponse.SelectedValue" (ngModelChange)="dataChanged($event,i,d)"
                                    [name]="subResponse.AttributeId">
                                </mat-checkbox>

                            </mat-grid-tile>
                        </ng-container>
                    </mat-grid-list>
                    <ng-container *ngFor="let subResponse of measureResponse?.DetailedInfo[0]?.Attributes" >
                        <div  *ngIf="subResponse.DataType=='String' && subResponse.Name=='Notes'">
                        <mat-label class="noteLabel">{{subResponse.Name}}</mat-label>
                        <mat-form-field class="font noteTextArea" appearance="outline">
                            <textarea appInputlengthFilter [(ngModel)]="subResponse.SelectedValue" rows="5"
                            cols="15" matInput
                            [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                            [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''"
                            [maxlength]="subResponse.Size" (ngModelChange)="dataChanged($event,i,d)"
                            [name]="subResponse.AttributeId"></textarea>
                        </mat-form-field>
                    </div>
                      </ng-container>
                    <div class="align">
                        <mat-card-actions>
                            <button mat-button color="primary" class="save-cancel-btn"
                            [disabled]="response?.ActionDisable != 'Yes'"   (click)="saveMeasure();">Save</button>
                            <button mat-button color="primary" class="save-cancel-btn"
                                (click)="addMeasureClose();">Cancel</button>
                        </mat-card-actions>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <table *ngIf="response?.MeasureGridValue?.length > 0" class="mat-elevation-z8 table">
        <caption class="caption whitesmoke" [class]="workorderService.getCustomCSSForOfficeId(false)">
            <th>Installed Measures</th>
        </caption>
        <thead>
            <tr>
                <ng-container *ngFor="let head of response?.MeasureGridValue[0] |  keyvalue : originalOrder">
                    <th class="table-header whitesmoke" [class]="workorderService.getCustomCSSForOfficeId(false)"
                        *ngIf="head.key != 'Record Id' && head.key !='Measure Id'">{{head.key}}</th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
    
            <tr *ngFor="let item of response?.MeasureGridValue" class="align">
                <ng-container *ngFor="let list of item | keyvalue : originalOrder">
                    <td class="table-data" *ngIf="list.key!= 'Record Id' && list.key !='Measure Id'">
                        <span *ngIf="item[list.key] != 'Edit,Delete'"> {{item[list.key]}}</span>
                        <span *ngIf="item[list.key] == 'Edit,Delete'">
                            <mat-icon *ngIf="response?.ShowAddMeasures == 1"
                                (click)="updateMeasure(item['Measure Id'], true, item['Record Id']);"
                                class="cursor">edit</mat-icon> &nbsp;&nbsp;&nbsp;
                            <mat-icon (click)="deleteMeasure(item['Record Id'])" class="cursor">delete</mat-icon>
                        </span>
                    </td>
                </ng-container>
            </tr>
    
        </tbody>
        <tfoot>
            <tr>
                <td class="text-align-right" [attr.colspan]="totalSavingCount"><b>Total Energy Saving (kWh):
                        {{response.TotalInstalledEnergySavings}}</b></td>
                <td class="text-align-center"><b>Total Cost ($):
                        {{response.TotalInstalledCostsavings}}</b></td>
            </tr>
        </tfoot>
    </table>
    
    <table *ngIf="response.MeasureGridValue1?.length > 0" class="mat-elevation-z8 table">
        <caption class="caption whitesmoke" [class]="workorderService.getCustomCSSForOfficeId(false)">
            <th>Recommended Measures</th>
        </caption>
        <thead>
            <tr>
                <ng-container *ngFor="let head of response?.MeasureGridValue1[0] |  keyvalue : originalOrder">
                    <th class="table-header whitesmoke" [class]="workorderService.getCustomCSSForOfficeId(false)"
                        *ngIf="head.key != 'Record Id' && head.key !='Measure Id'">{{head.key}}</th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
    
            <tr *ngFor="let item of response?.MeasureGridValue1" class="align">
                <ng-container *ngFor="let list of item | keyvalue : originalOrder">
                    <td class="table-data" *ngIf="list.key!= 'Record Id' && list.key !='Measure Id'">
                        <span *ngIf="item[list.key] != 'Edit,Delete'"> {{item[list.key]}}</span>
                        <span *ngIf="item[list.key] == 'Edit,Delete'">
                            <mat-icon *ngIf="response?.ShowAddMeasures == 1"
                                (click)="updateMeasure(item['Measure Id'], true, item['Record Id']);"
                                class="cursor">edit</mat-icon> &nbsp;&nbsp;&nbsp;
                            <mat-icon (click)="deleteMeasure(item['Record Id'])" class="cursor">delete</mat-icon>
                        </span>
                    </td>
                </ng-container>
            </tr>
    
        </tbody>
        <tfoot>
            <tr>
                <td class="text-align-center" [attr.colspan]="totalSavingCount"><b>Total Saving (kWh):
                        {{response.TotalRecommendedEnergySavings}}</b></td>
                <td class="text-align-center"><b>Total Cost ($):
                        {{response.TotalRecommendedCostSavings}}</b></td>
            </tr>
        </tfoot>
    </table>
</div>
