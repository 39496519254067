<mat-accordion *ngIf="customer.AccountNumber !== undefined">
    <mat-expansion-panel [expanded]="true" style=" margin-top:1vw !important; margin-bottom: 0;margin-right: 1.5vw;">
        <mat-expansion-panel-header style="font-size: 13px;padding: 0 24px 10px;">
          
            <table class="mat-elevation-z8" style="height: 3vw !important; font-size: 0.9vw ">
                <tr [class]="workorderService.getCustomCSSForOfficeId(false)" style="color:whitesmoke">
                    <th>Customer Name
                     
                    </th>
                    <th style="text-align: center;width:5.5vw">Account No</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Rate Code</th>
                    <th>External Id</th>
                    <th>Provider</th>
                  
                </tr>
               
                <tr>
                    <td style="text-align: center;">{{customer.Name | titlecase}}
                        <button mat-icon-button color="accent" class="editicon-cs" matTooltip="Edit Customer Name" style="height: 32px;
                        width: 48px;padding-bottom: 0.5em;"  *ngIf="!editing" (click)=startEditingName() >
                            <mat-icon class="myIcon">edit</mat-icon>
                        </button>
                    </td>
                    <td style="text-align: center; width:1.5vw"> {{customer.AccountNumber}} </td>
                    <td style="text-align: center"> {{customer.StartDate | date:'M/d/yyyy'}}</td>
                    <td style="text-align: center"> {{customer.LastModifiedDate | date:'M/d/yyyy'}} </td>
                    <td style="text-align: center"> {{customer.RateCode}} </td>
                    <td style="text-align: center"> {{customer.ExternalId}} </td>
                    <td style="text-align: center"> {{customer.ProviderName}} </td>
                    <table class="view-usage">
                        <tr>
                          <td>
                            <a class="custom-link" (click)="showUsageDialog($event)">View Usage</a>
                          </td>
                        </tr>
                    </table>
                </tr>
            </table>
        </mat-expansion-panel-header>

       
        <div class="table-detail">
            <table class="mat-elevation-z8 addressTable" style="font-size: 0.9vw !important">
                <tr [class]="workorderService.getCustomCSSForOfficeId(false)" style=" color:whitesmoke;">
                    <th class="tableHeader">Mailing Address</th>
                    <th class="tableHeader">Service Address</th>
                    <button mat-icon-button color="accent" class="editicon-cs" matTooltip="Edit" style="height: 37px;
                    width: 48px;"  *ngIf="!editing" (click)=startEditingAddress() >
                        <mat-icon class="myIcon">edit</mat-icon>
                    </button>
                </tr>

                <tr class="rowStyle">
                    <td> {{ customer.MailingStreetAddress1 | titlecase}} </td>
                    <td> {{ customer.ServiceStreetAddress1 | titlecase}}</td>
                </tr>
                <tr class="rowStyle">
                    <td> {{ customer.MailingBlock | titlecase}} </td>
                    <td> {{ customer.ServiceBlock | titlecase}}</td>
                </tr>
                <tr class="rowStyle">
                    <td> {{ customer.MailingStreetAddress2 | titlecase}} </td>
                    <td> {{ customer.ServiceStreetAddress2 | titlecase}}</td>
                </tr>
                <tr class="rowStyle">
                    <td> {{ customer.MailingCity | titlecase}}, {{ customer.MailingState | uppercase}}
                        {{ customer.MailingZipcode}}</td>
                    <td> {{ customer.ServiceCity | titlecase}}, {{ customer.ServiceState | uppercase}}
                        {{ customer.ServiceZipcode}} </td>
                </tr>
            </table>

            <!-- Display only -->
            <table class="mat-elevation-z8 addressTable-csut" *ngIf="!editing" style="font-size: 0.9vw !important">
                <tr [class]="workorderService.getCustomCSSForOfficeId(false)" style="color:whitesmoke;">
                    <th class="tableHeade1" style="width: 20%;">
                        Contact Permissions
                    </th>
                    <th class="tableHeader flex-th">
                        <span style="padding-right: 47%; margin-right: 105%;margin-left: 13%;">Contacts</span>
                        <button mat-icon-button color="accent" class="editicon-cs" matTooltip="Edit" style="height: 37px;
                        width: 48px;"  *ngIf="!editing" (click)=startEditing() >
                            <mat-icon class="myIcon">edit</mat-icon>
                        </button>
                    </th>
                </tr>
                <tr class="rowStyle">
                    <td class="donotmarkup row-single">
                        <div>
                            <!-- <span class="donottext">Do Not Call</span> -->
                            <!-- <mat-icon *ngIf="customer.DoNotCall" style="font-size:1.2vw !important">check</mat-icon> -->
                            <mat-checkbox class="cb-margin" [(ngModel)]="editCust.DoNotCall" disabled>Do Not Call</mat-checkbox>
                        </div>
                        <div>  
                            <!-- <span class="donottext">Do Not Email</span> -->
                            <!-- <mat-icon *ngIf="customer.DoNotEmail" style="font-size:1.2vw !important">check</mat-icon> -->
                            <mat-checkbox class="cb-margin" [(ngModel)]="editCust.DoNotEmail" disabled>Do Not Email</mat-checkbox>
                        </div>
                    </td>
                    <td style="text-align: initial;">
                        <p class="remove-mar"><span class="text-info">Primary Phone: </span>{{ customer.PrimaryPhone }}&nbsp;&nbsp;&nbsp;<span class="text-info">Alternate Phone: </span>{{ customer.AlternatePhone }}</p> 
                    </td>
                </tr>
                <tr class="rowStyle">
                    <td class="donotmarkup" style="text-align: initial;">
                        <!-- <span class="donottext">Do Not Mail</span> -->
                        <mat-checkbox class="cb-margin" [(ngModel)]="editCust.DoNotMail" disabled>Do Not Mail</mat-checkbox>
                        <!-- <mat-icon *ngIf="customer.DoNotMail" style="font-size:1.2vw !important">check</mat-icon> -->
                    </td>
                    <td style="text-align: initial;">
                        <span class="text-info">Email: </span>{{ customer.Email }}
                    </td>
                </tr>
                <!-- <tr>
                    <td colspan="2" style="text-align: center">
                        <button mat-raised-button color="primary" *ngIf="!editing" (click)="startEditing()">EDIT</button>

                        <button mat-icon-button color="accent"  *ngIf="!editing" (click)=startEditing()>
                            <mat-icon>edit</mat-icon>
                        </button>
                    </td>
                </tr> -->
            </table>

            <!-- Editing table -->
            <table class="mat-elevation-z8 addressTable-csut" *ngIf="editing" style="font-size: 0.9vw !important">
                <tr [class]="workorderService.getCustomCSSForOfficeId(false)" style="color:whitesmoke;">
                    <th class="tableHeader1" style="width: 20%;">
                        Contact Permissions
                    </th>
                    <th class="tableHeader" style="width: 67%;">
                        Contacts
                    </th>
                    <th style="background: white;"></th>
                </tr>
                <tr class="rowStyle">
                    <td class="row-single">
                        <div>
                            <mat-checkbox class="cb-margin" [(ngModel)]="editCust.DoNotCall">Do Not Call</mat-checkbox>
                        </div>
                        <div>
                            <mat-checkbox class="cb-margin" [(ngModel)]="editCust.DoNotEmail">Do Not Email</mat-checkbox>
                        </div>
                    </td>
                    <td>
                        <div class="row-single">
                            <mat-form-field style="width:80% !important">
                                <input matInput placeholder="Primary Phone" [(ngModel)]="editCust.PrimaryPhone">
                            </mat-form-field>
                            <mat-form-field style="width:80% !important">
                                <input matInput placeholder="Alternate Phone" [(ngModel)]="editCust.AlternatePhone">
                            </mat-form-field>
                        </div>
                    </td>
                </tr>
                <tr class="rowStyle">
                    <td style="text-align: initial;">
                        <mat-checkbox class="cb-margin" [(ngModel)]="editCust.DoNotMail">Do Not Mail</mat-checkbox>
                    </td>
                    <td style="text-align: initial;">
                        <mat-form-field style="width:80% !important">
                            <input matInput placeholder="Email" [formControl]="emailFC" required>
                            <mat-error *ngIf="emailFC.invalid">Not a valid email</mat-error>
                        </mat-form-field>
                    </td>
                </tr>
                <tr class="rowStyle">
                    <td colspan="2" style="text-align: center">
                        <button mat-raised-button color="primary"  [disabled]='emailFC.invalid' *ngIf="editing" (click)="doSave()">SAVE</button>
                        <button style="margin-left:0.5vw"  mat-raised-button color="primary" *ngIf="editing"
                            (click)="cancelEditing()">CANCEL</button>
                    </td>

                </tr>
            </table>
        </div>
    </mat-expansion-panel>
</mat-accordion>

<!-- For the interactions/workorder and details display -->
<div style="margin-top:1.5vw !important">
    <mat-card style="float: left; width: 35%; min-height: 17vw !important;overflow: auto;">
        <mat-card-header [class]="workorderService.getCustomCSSForOfficeId(false)" style="align-items: center; font-size:0.9vw;color:whitesmoke ;">
            Interactions
            <button matTooltip="Add" mat-button (click)="addNewInteraction()" style="background: transparent;margin-left:18vw !important">
                <mat-icon>
                    add
                </mat-icon>
            </button>
        </mat-card-header>

        <mat-card-content style="height: 2vw !important;">
            <mat-spinner *ngIf="loadInteraction" [diameter]="40"
                style="margin:0 auto; text-align:center; margin-top:2vw"></mat-spinner>
            <table *ngIf="interactionDisplay && !loadInteraction"
                style="width: 100% !important; margin-top: 1.2vw !important" mat-table [dataSource]="dataSource1"
                class="mat-elevation-z8">
                <ng-container matColumnDef="type">
                    <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(false)" *matHeaderCellDef style="text-align: left; font-size: 0.8vw">Type</th>
                    <td style="font-size:0.8vw; text-align: left" mat-cell *matCellDef="let interaction">
                        {{interaction.TypeDescription | titlecase}} </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(false)" *matHeaderCellDef style="text-align: left;font-size: 0.8vw">Why</th>
                    <td style="font-size:0.8vw; text-align: left" mat-cell *matCellDef="let interaction">
                        {{interaction.WhyDescription | titlecase}} </td>
                </ng-container>
                <ng-container matColumnDef="date">
                    <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(false)" style="text-align: left;font-size: 0.8vw" *matHeaderCellDef>Start Date</th>
                    <td mat-cell style="font-size:0.8vw;text-align: left" *matCellDef="let interaction">
                        {{(interaction.StartDate == null ? interaction.CreatedDate : interaction.StartDate)| date:'M/d/yyyy  h:mm:ss a'}}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns1;" (click)="handleInteractionClick(row)"
                    class="curr-interaction"></tr>
            </table>
            <div *ngIf="!interactionDisplay  && !loadInteraction" style="
            vertical-align: middle;
            text-align: center;
            margin: 0 auto;
            margin-top: 10vw;">{{interactionMessage}}</div>

        </mat-card-content>
        <!-- <mat-paginator #paginator [pageSize]="5" showFirstLastButtons></mat-paginator> -->
    </mat-card>
    <mat-card style="float: right; width: 55%; height: 70vh !important; margin-bottom: 1vw; margin-right: 1.5vw;">
        <!--   <mat-card-header style="align-items: center;font-size:0.9vw;color:whitesmoke ;background: #2196F3;
        height: 2.5%;padding-top:1vw;padding-bottom:1vw">
            Details <mat-spinner *ngIf="showWorkorder && loadAttachments" [diameter]="30" style="color: white"
                style="margin:0 auto; text-align:center;margin-top:0.5vw"></mat-spinner>
        </mat-card-header> -->
        <mat-card-content style="height: 100% !important">
            <mat-tab-group #detailTabs [class]="workorderService.getCustomTabCSSForOfficeId()"
                style="width: 99% !important;height: 100% !important;">
                <mat-tab label="Detail">

                    <mat-spinner *ngIf="showWorkorderLoad && !showInteractionLoad" [diameter]="70" color="primary"
                        style="margin: 0 auto; width:50%; height: 100%; margin-top: 0.5vw">
                    </mat-spinner>

                    <mat-spinner *ngIf="showInteractionLoad && !showWorkorderLoad" [diameter]="70" color="primary"
                        style="margin: 0 auto; width:50%; height: 100%;margin-top: 0.5vw">
                    </mat-spinner>


                    <label *ngIf="!showWorkorder && !showInteraction" style="float: left;         
                            width: 100%;
                            text-align: center;
                            font-size: 1vw;margin-top:1vw">Select Workorder / Interaction</label>

                    <table *ngIf="showInteraction" class="mat-elevation-z8"
                        style="width:90% !important; margin-top: 3vw;font-size: 0.9vw; margin-left:3vw">
                        <tr style="background-color:#CFD8DC;color:black;height: 2vw;">
                            <th [class]="workorderService.getCustomCSSForOfficeId(false)">User</th>
                            <th [class]="workorderService.getCustomCSSForOfficeId(false)">Start Date</th>
                            <th [class]="workorderService.getCustomCSSForOfficeId(false)">End Date</th>
                        </tr>
                        <tr class="rowStyle" style="height: 2vw !important">
                            <td>{{selectedInteraction.User}}</td>
                            <td>{{selectedInteraction.StartDate | date :'M/dd/yy  h:mm:ss a'}}</td>
                            <td>{{selectedInteraction.EndDate | date :'M/dd/yy  h:mm:ss a'}}</td>
                        </tr>
                    </table>

                    <table *ngIf="showInteraction" class="mat-elevation-z8"
                        style="width:90% !important;margin-left:3vw !important; margin-top: 3vw;font-size: 0.9vw">
                        <tr style="background-color:#CFD8DC;color:black;height: 2vw;">
                            <th [class]="workorderService.getCustomCSSForOfficeId(false)">Type</th>
                            <th [class]="workorderService.getCustomCSSForOfficeId(false)">Why</th>
                            <th [class]="workorderService.getCustomCSSForOfficeId(false)">Complete</th>
                            <th [class]="workorderService.getCustomCSSForOfficeId(false)">Reason Not Done</th>
                            <th [class]="workorderService.getCustomCSSForOfficeId(false)">Marketing</th>
                        </tr>
                        <tr class="rowStyle" style="height: 2vw !important">
                            <td>{{selectedInteraction.TypeDescription | titlecase}}</td>
                            <td>{{selectedInteraction.WhyDescription | titlecase}}</td>
                            <td matTooltip="Check">
                                <mat-icon *ngIf="interactionIsComplete">check</mat-icon>
                            </td>
                            <td>{{selectedInteraction.ReasonDescription}}</td>
                            <td>{{selectedInteraction.MarketingDescription}}</td>
                        </tr>

                    </table>
                    <table *ngIf="showInteraction" class="mat-elevation-z8"
                        style="width:94% !important;margin-bottom: 2vw; margin-left:2vw; margin-top: 3vw;font-size: 0.9vw">
                        <tr style="background-color:#CFD8DC;color:black;height: 2vw;">
                            <th [class]="workorderService.getCustomCSSForOfficeId(false)">Notes</th>
                        </tr>
                        <tr class="rowStyle" style="height: 2.5vw !important; overflow-wrap: anywhere;">
                            <td><div>{{selectedInteraction.Notes}}</div></td>
                        </tr>
                    </table>

                    <span *ngIf="showWorkorder && !showWorkorderLoad && !showInteractionLoad"
                        style="margin-left:1vw; float:left;font-size: 1.2vw;margin-top:0.5vw; width:54% !important">
                        {{selectedWorkorder.ProgramName}}
                    </span>
                    <a *ngIf="showWorkorder && !showWorkorderLoad && !showInteractionLoad" href=""
                        (click)="showWorkorderHistory(selectedWorkorder.WorkorderId);">
                        <label style="margin-right:1vw;  
                        text-align: center;cursor: pointer;
                        font-size: 1.2vw;margin-top:0.5vw;float:right">Workorder :
                            {{selectedWorkorder.WorkorderName}}</label></a>
                    <mat-card *ngIf="showWorkorder && !showWorkorderLoad && !showInteractionLoad"
                        style="width:40%;margin-top:1vw; margin-left:1vw; height:7vw; float:left">
                        <mat-card-header class="workorderHeaders">Customer Details</mat-card-header>
                        <mat-card-content>
                            <label class="labelStyle"> {{selectedWorkorder.CustomerName | titlecase}} </label>
                            <label class="labelStyle"> {{selectedWorkorder.CustomerStreet | titlecase}} </label>
                            <label class="labelStyle"> {{selectedWorkorder.CustomerCity | titlecase}},
                                {{selectedWorkorder.CustomerState | uppercase}} {{selectedWorkorder.CustomerZip}}
                            </label>
                        </mat-card-content>
                    </mat-card>
                    <mat-card *ngIf="showWorkorder && !showWorkorderLoad && !showInteractionLoad"
                        style="width:40%; height:7vw; margin-right:1vw; float:right;margin-top:1vw;">
                        <mat-card-header class="workorderHeaders">Payee Details</mat-card-header>
                        <mat-card-content>
                            <div>
                                <label class="labelStyle"> {{selectedWorkorder.PayeeName | titlecase}} </label>
                                <label class="labelStyle"> {{selectedWorkorder.PayeeEmail}}</label>
                                <label class="labelStyle"> {{selectedWorkorder.PayeeAddress | titlecase}} </label>
                                <label class="labelStyle"> {{selectedWorkorder.PayeeCity | titlecase}},
                                    {{selectedWorkorder.PayeeState | uppercase}} {{selectedWorkorder.PayeeZip}}
                                </label>
                            </div>

                        </mat-card-content>
                    </mat-card>
                    <mat-card *ngIf="showWorkorder && !showWorkorderLoad && !showInteractionLoad"
                        style="width:40%;  height:12vw;margin-left:1vw;  float:left;margin-top:0.5vw;">
                        <mat-card-header class="workorderHeaders">Rebate Details</mat-card-header>
                        <mat-card-content>
                            <label class="labelStyle"> Amount Paid : {{selectedWorkorder.AmountPaid}} </label>
                            <label class="labelStyle" style="margin-top:0.5vw"> Check # :
                                {{selectedWorkorder.CheckNumber}}
                            </label>
                            <label class="labelStyle" style="margin-top:0.5vw"> Date Paid :
                                {{selectedWorkorder.DatePaid | date : 'M/dd/yy'}} </label>
                                <label class="labelStyle" style="margin-top:0.5vw"> Emailed or Mailed : {{selectedWorkorder.MailorEmail}}
                                     </label>
                            <label class="labelStyle" style="margin-top:0.5vw"> Payment Status
                                :{{selectedWorkorder.PaymentStatus}} </label>
                            <label class="labelStyle" style="margin-top:0.5vw"> WorkorderStatus Date :
                                {{selectedWorkorder.WorkorderStatusDate | date : 'M/dd/yy'}} </label>
                            <label class="labelStyle" style="margin-top:0.5vw"> Workorder Application Source :
                                {{selectedWorkorder.WorkorderApplicationSource? selectedWorkorder.WorkorderApplicationSource:' '}} </label>    
                        </mat-card-content>
                    </mat-card>

                    <mat-card *ngIf="showWorkorder && !showWorkorderLoad && !showInteractionLoad"
                        style="width:40%;  height:12vw; margin-right:1vw; float:right;margin-top:0.5vw">
                        <mat-card-header class="workorderHeaders">
                            Contractor Details
                        </mat-card-header>
                        <mat-card-content style="margin-top:0.5vw">
                            <label class="labelStyle" style="text-align: left;"> Service Provider Name :
                                {{selectedWorkorder.ServiceProviderName}}</label>
                            <label class="labelStyle" style="margin-top:0.5vw;text-align: left;"> Contractor Phone :
                                {{selectedWorkorder.ContractorPhone}}</label>
                            <label class="labelStyle" style="margin-top:0.5vw;text-align: left;"> Contractor Email :
                                {{selectedWorkorder.ContractorEmail}}</label>
                            <label class="labelStyle" style="margin-top:0.5vw;text-align: left;"> Company Contact :
                                {{selectedWorkorder.CompanyContact}} </label>
                        </mat-card-content>
                    </mat-card>
                    <mat-card [hidden]="!(!showWorkorderLoad && showWorkorder && !showInteractionLoad)" style="vertical-align: middle;
                    margin-top: 26.5vw !important;
                    margin: 0 auto;
                    width: 50%;
                    height: 11vw;">
                        <mat-card-header class="workorderHeaders">Attachments</mat-card-header>
                        <mat-card-content [hidden]="!showWorkorder">
                            <div *ngIf="showAttachMessage" style="text-align: center;margin:1vw">
                                <label>{{attachmentMessage}}</label></div>
                            <table *ngIf="!showAttachMessage"
                                style="width: 100% !important; margin-top: 0.5vw !important" mat-table
                                [dataSource]="dataSource3" class="mat-elevation-z8">
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(false)" *matHeaderCellDef style="font-size:0.8vw">Name</th>
                                    <td style="font-size:0.8vw" mat-cell *matCellDef="let attachment">
                                        {{attachment.FileName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="date">
                                    <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(false)" *matHeaderCellDef style="font-size:0.8vw">Date</th>
                                    <td style="font-size:0.8vw" mat-cell *matCellDef="let attachment">
                                        {{attachment.DateUploaded |  date:'M/d/yyyy'}}</td>
                                </ng-container>
                                <tr mat-header-row [class]="workorderService.getCustomCSSForOfficeId(false)" *matHeaderRowDef="displayedColumns4"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns4;"
                                    (click)="handleAttachmentClick(row)" class="curr-workorder"></tr>
                            </table>
                            <mat-paginator #attachpaginator [pageSize]="3" showFirstLastButtons></mat-paginator>
                        </mat-card-content>
                    </mat-card>
                    <!-- <mat-card *ngIf="showWorkorder && !showWorkorderLoad && !showInteractionLoad" style="vertical-align: middle;
                        margin-top: 23.5vw !important;
                        margin: 0 auto;
                        width: 50%;
                        height: 7vw;">
                        <mat-card-header class="workorderHeaders">
                            Contractor Details
                        </mat-card-header>
                        <mat-card-content style="margin-top:0.5vw">
                            <label class="labelStyle" style="text-align: left;"> Service Provider Name :
                                {{selectedWorkorder.ServiceProviderName}}</label>
                            <label class="labelStyle" style="margin-top:0.5vw;text-align: left;"> Contractor Phone :
                                {{selectedWorkorder.ContractorPhone}}</label>
                            <label class="labelStyle" style="margin-top:0.5vw;text-align: left;"> Contractor Email :
                                {{selectedWorkorder.ContractorEmail}}</label>
                            <label class="labelStyle" style="margin-top:0.5vw;text-align: left;"> Company Contact :
                                {{selectedWorkorder.CompanyContact}} </label>
                        </mat-card-content>
                    </mat-card> -->
                    <mat-card *ngIf="showWorkorder && !showWorkorderLoad && !showInteractionLoad"
                        style="width:40%;  height:11vw; margin-right:1vw; float:right;margin-top:0.5vw">
                        <mat-card-header class="workorderHeaders">
                            Workorder Notes
                        </mat-card-header>
                        <mat-card-content style="margin-top:0.5vw">
                            <label *ngIf="selectedWorkorder.InstalledWONotes"
                                style="font-size: 0.8vw">{{selectedWorkorder.InstalledWONotes}}</label>
                            <label *ngIf="!selectedWorkorder.InstalledWONotes" style="font-size: 0.8vw">No Workorder
                                Notes</label>
                        </mat-card-content>
                    </mat-card>

                    <mat-card *ngIf="showWorkorder && !showWorkorderLoad && !showInteractionLoad && ['Deactivated', 'Canceled'].includes(selectedWorkorder.WorkorderStatus)"
                    style="width:40%;  height:11vw; margin-right:1vw; float:right;margin-top:0.5vw">
                        <mat-card-header class="workorderHeaders" style="color:red;font-size: 1vw">
                            Deactivate/Cancel Reason
                        </mat-card-header>
                        <mat-card-content style="margin-top:0.5vw">
                            <label style="font-size: 0.8vw"
                                *ngIf="selectedWorkorder.DeactivationReasons">{{selectedWorkorder.DeactivationReasons}}</label>
                            <label style="font-size: 0.8vw" *ngIf="!selectedWorkorder.DeactivationReasons"></label>
                        </mat-card-content>
                    </mat-card>
                    <mat-card *ngIf="showWorkorder && !showWorkorderLoad && !showInteractionLoad"
                    style="width:40%;  height:11vw; margin-right:1vw; float:right;margin-top:0.5vw">
                        <mat-card-header class="workorderHeaders" style="color:red;font-size: 1vw">
                            Workorder Exceptions
                        </mat-card-header>
                        <mat-card-content style="margin-top:0.5vw">
                            <label style="font-size: 0.8vw"
                                *ngIf="selectedWorkorder.WorkorderExceptions">{{selectedWorkorder.WorkorderExceptions}}</label>
                            <label style="font-size: 0.8vw" *ngIf="!selectedWorkorder.WorkorderExceptions">No
                                Workorder
                                Exceptions</label>
                        </mat-card-content>
                    </mat-card>
                </mat-tab>

                <mat-tab label="Measures" #measuresTab [disabled]='!showWorkorder'>

                    <!--  <mat-spinner *ngIf="measuresSearching && measures.MeasuresArray.length === 0" [diameter]="70"
                        color="primary" style="margin: 0 auto; width:50%; height: 100%; margin-top:0.5vw">
                    </mat-spinner> -->


                    <div *ngIf="!measuresSearching && measures.MeasuresArray.length === 0"
                        style="color: red; font-size: 1vw;">
                        No measures for the selected
                        workorder</div>
                    <div [hidden]="!measuresSearching  &&  !(measures.MeasuresArray.length > 0)">
                        <mat-paginator style="float: right !important" #MeasurePaginator [length]="totalRows$ | async"
                            [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

                        <button matTooltip="Collapse All" matTooltip="Unfold less" mat-button (click)="closeAll()" style="margin-top: 0.5vw">
                            <mat-icon style="color: black;" aria-hidden="false" aria-label="collapse">unfold_less
                            </mat-icon>
                        </button>
                        <br>
                        <mat-accordion #accordion="matAccordion" displayMode="flat" [multi]="true" class="mat-table"
                            style=" table-layout: fixed;width:100% !important;">

                            <br>
                            <mat-expansion-panel *ngFor="let measure of displayedRows$ | async" style="margin: 0.2vw">
                                <mat-expansion-panel-header class="mat-row">
                                    <span style="width:30%" class="mat-cell">{{measure.Name}}</span>
                                    <span style="width:25%" class="mat-cell">{{measure.Category}}</span>
                                    <span style="width:15%" class="mat-cell">{{measure.Quantity}}</span>
                                    <span style="width:24%" class="mat-cell">{{measure.Status}}</span>
                                    <span style="width:15%" class="mat-cell">{{measure.Cost}}</span>
                                </mat-expansion-panel-header>
                                <tr *ngIf="measure.Notes">Notes : {{measure.Notes}}</tr>
                                <div *ngIf=" measure.MeasureAttributes.length === 0"
                                    style="color: red; font-size: 1vw;">No
                                    attributes for the current measure</div>
                                <table *ngIf="measure.MeasureAttributes.length > 0" class="mat-elevation-z8"
                                    style="table-layout: fixed;width:50%; margin:0 auto">
                                    <th [class]="workorderService.getCustomCSSForOfficeId(false)" class="backgroundClass"
                                        style="text-align: left; font-size: 0.7vw ; color:whitesmoke;">
                                        Attribute Name
                                    </th>
                                    <th [class]="workorderService.getCustomCSSForOfficeId(false)" class="backgroundClass"
                                        style="text-align: left; font-size: 0.7vw;color:whitesmoke;">
                                        Attribute Value
                                    </th>
                                    <tbody>

                                        <tr *ngFor="let attr of measure.MeasureAttributes" class="alternateColors">
                                            <td style="font-size: 0.7vw">{{attr.Name}}</td>
                                            <td style="font-size: 0.7vw">{{attr.Value}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </mat-expansion-panel>

                        </mat-accordion>
                    </div>


                </mat-tab>

                <mat-tab label="Exceptions" #exceptionsTab [disabled]='!showWorkorder'>
                    <!-- 
                    <mat-spinner *ngIf="exceptionSearching" [diameter]="70" color="primary"
                        style="margin: 0 auto; width:50%; height: 100%; margin-top:0.5vw">
                    </mat-spinner>
 -->

                    <div *ngIf="!exceptionSearching && measures.MeasuresArray.length === 0"
                        style="color: red; font-size: 1vw;">
                        No exceptions for the selected workorder
                    </div>
                    <div [hidden]="!exceptionSearching  &&  !(measures.MeasuresArray.length > 0)">
                        <!--
                        <mat-paginator style="float: right !important" #MeasurePaginator [length]="totalRows$ | async"
                            [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

                        <button matTooltip="Collapse All" mat-button (click)="closeAll()" style="margin-top: 0.5vw">
                            <mat-icon style="color: black;" aria-hidden="false" aria-label="collapse">unfold_less
                            </mat-icon>
                        </button>
                        <br>
                        <mat-accordion #accordion="matAccordion" displayMode="flat" [multi]="true" class="mat-table"
                            style=" table-layout: fixed;width:100% !important;">

                            <br>
                            <mat-expansion-panel *ngFor="let measure of displayedRows$ | async" style="margin: 0.2vw">
                                <mat-expansion-panel-header class="mat-row">
                                    <span style="width:30%" class="mat-cell">{{measure.Name}}</span>
                                    <span style="width:25%" class="mat-cell">{{measure.Category}}</span>
                                    <span style="width:15%" class="mat-cell">{{measure.Quantity}}</span>
                                    <span style="width:24%" class="mat-cell">{{measure.Status}}</span>
                                    <span style="width:15%" class="mat-cell">{{measure.Cost}}</span>
                                </mat-expansion-panel-header>
                                <tr *ngIf="measure.Notes">Notes : {{measure.Notes}}</tr>
                                <div *ngIf=" measure.MeasureAttributes.length === 0"
                                    style="color: red; font-size: 1vw;">No
                                    attributes for the current measure</div>
                                <table *ngIf="measure.MeasureAttributes.length > 0" class="mat-elevation-z8"
                                    style="table-layout: fixed;width:50%; margin:0 auto">
                                    <th class="backgroundClass"
                                        style="text-align: left; font-size: 0.7vw ; color:whitesmoke;">
                                        Attribute Name
                                    </th>
                                    <th class="backgroundClass"
                                        style="text-align: left; font-size: 0.7vw;color:whitesmoke;">
                                        Attribute Value
                                    </th>
                                    <tbody>

                                        <tr *ngFor="let attr of measure.MeasureAttributes" class="alternateColors">
                                            <td style="font-size: 0.7vw">{{attr.Name}}</td>
                                            <td style="font-size: 0.7vw">{{attr.Value}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </mat-expansion-panel>

                        </mat-accordion>
                        -->
                    </div>


                </mat-tab>
                <mat-tab label="Add Interactions" #addInteractionsTab  *ngIf="showAddInteractions">
                          <table style="font-size: 1vw;">
                            <tr>
                              <td>
                                <span style="float: right;">{{ timer }}</span>
                              </td>
                            </tr>
                          </table>
                          <br>
                          <table style="font-size: 1vw;">
                            <tr>
                              <td style="text-align: center">
                                <mat-form-field >
                                  <mat-label>Type</mat-label>
                                  <mat-select [(ngModel)]="selectedTypeCode" (selectionChange)="changeType($event)">
                                    <mat-option *ngFor="let typeobj of types" [value]="typeobj" style="font-size: 11px;">
                                      {{typeobj.value}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>
                              <td style="text-align: center">
                                <mat-form-field>
                                  <mat-label>Why</mat-label>
                                  <mat-select [(ngModel)]="selectedWhy">
                                    <mat-option *ngFor="let why of whys" [value]="why" style="font-size: 11px;">
                                      {{why.value}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>
                              <td style="text-align: center">
                                <mat-form-field >
                                  <mat-label>Marketing</mat-label>
                                  <mat-select [(ngModel)]="selectedMarketing">
                                    <mat-option *ngFor="let marketObj of marketing" [value]="marketObj" style="font-size: 11px;">
                                      {{marketObj.value}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="3">
                                <mat-form-field style="width: 100%;">
                                  <textarea matInput rows="3" placeholder="Notes" value="" [(ngModel)]="notesInput"></textarea>
                                </mat-form-field>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <mat-checkbox #completeCheckbox (change)="onCompleteClick($event)">Complete
                                </mat-checkbox>
                              </td>
                              <td style="width:100% !important; padding-right:4vw">
                                <mat-form-field style="width:18vw">
                                  <mat-label>Reason</mat-label>
                                  <mat-select [disabled]="checkIfComplete" [(ngModel)]="selectedReason">
                                    <mat-option *ngFor="let reason of reasons" [value]="reason" style="font-size: 11px;">
                                      {{reason.value}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>
                              <td>
                                  &nbsp;
                              </td>
                            </tr>
                            <tr>
                              <td colspan= "3" style="text-align: center">
                                <button mat-button color="primary" style="font-weight: 600;"  (click)="addInteraction();" [disabled]="checkGoodDropForm() || adding">Save</button>
                                <button mat-button color="primary" style="font-weight: 600;"  [mat-dialog-close]="true" (click)="closeInteractions()">Cancel</button>
                              </td>
                            </tr>
                            <tr>
                              <td>{{statusMessage}}</td>
                            </tr>
                          </table>
                </mat-tab>
            </mat-tab-group>

        </mat-card-content>
    </mat-card>
    <mat-card style="float:left; clear: left; width: 35%; height:auto; margin-top:1vw;margin-bottom: 1vw"
        [hidden]="customerService.isBbcs()">
        <mat-card-header class="mat-header-color" style="align-items: center;font-size:0.9vw;color:whitesmoke ;
        padding-top:0.8vw;padding-bottom: 0.8vw">
            Work Orders
        </mat-card-header>

        <mat-card-content>
            <mat-spinner *ngIf="loadWorkorder" [diameter]="40" style="margin:0 auto; text-align:center; margin-top:5vw">
            </mat-spinner>

            <table *ngIf="workorderDisplay && !loadWorkorder"
                style="width: 100% !important; margin-top: 1.2vw !important" mat-table [dataSource]="dataSource2"
                class="mat-elevation-z8">
                <ng-container matColumnDef="Name">
                    <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(false)" *matHeaderCellDef style="text-align: left;font-size: 0.8vw">Name</th>
                    <td style="font-size:0.8vw;text-align: left" mat-cell *matCellDef="let workorder">
                        {{workorder.Name}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="StatusDate">
                    <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(false)" *matHeaderCellDef style="text-align: left;font-size: 0.8vw">Status Date</th>
                    <td style="font-size:0.8vw;text-align: left" mat-cell *matCellDef="let workorder">
                        {{workorder.StatusDate |  date:'M/d/yyyy'}}</td>
                </ng-container>
                <ng-container matColumnDef="Status">
                    <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(false)" *matHeaderCellDef style="text-align: left;font-size: 0.8vw">Status</th>
                    <td style="font-size:0.8vw;text-align: left" mat-cell *matCellDef="let workorder">
                        {{workorder.Status}}</td>
                </ng-container>
                <tr mat-header-row  [class]="workorderService.getCustomCSSForOfficeId(false)"*matHeaderRowDef="displayedColumns3"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns3;" (click)="handleWorkorderClick(row)"
                    class="curr-workorder"></tr>
            </table>
            <div *ngIf="!workorderDisplay && !loadWorkorder" style="
            vertical-align: middle;
            text-align: center;
            margin: 0 auto;
            margin-top: 10vw;">No workorders for the current user</div>
        </mat-card-content>
        <!-- <mat-paginator #wopaginator [pageSize]="5" showFirstLastButtons></mat-paginator> -->
    </mat-card>

    <mat-card style="float:left; clear: left; width: 35%; height: auto; margin-top:1vw;margin-bottom: 1vw"
        [hidden]="!customerService.isBbcs()">
        <mat-card-header [class]="workorderService.getCustomCSSForOfficeId(false)" style="align-items: center;font-size:0.9vw;color:whitesmoke ;
        ">
            Enrollments
            <button mat-button [matMenuTriggerFor]="progmenu" matTooltip="Add"
                style="background: transparent;margin-left:18vw !important">
                <mat-icon>
                    add
                </mat-icon>
            </button>

            <mat-menu #progmenu="matMenu">
                <button mat-menu-item *ngFor="let enaction of enrollmentActions;"
                    (click)="handleEnrollment(enaction)">{{enaction.Value}}</button>
            </mat-menu>
        </mat-card-header>

        <mat-card-content>
            <mat-spinner *ngIf="loadWorkorder" [diameter]="40" style="margin:0 auto; text-align:center; margin-top:5vw">
            </mat-spinner>

            <div *ngIf="enrollments.EnrollmentArray.length < 1 && !loadWorkorder" style="
            vertical-align: middle;
            text-align: center;
            margin: 0 auto;
            margin-top: 2vw;">No enrollments for the current user</div>

            <mat-accordion class="mat-table" style="table-layout: fixed;width:100% !important;">
                <br>
                <mat-expansion-panel class="enrollmentPanel" *ngFor="let wo of displayedEnrollmentRows$ | async">
                    <mat-expansion-panel-header class="mat-row">
                        <mat-panel-title class="enroll-title">
                            <span class="mat-cell" style="font-size:0.8vw;text-align: left">{{wo.ProgramDesc}}</span>
                            <span class="mat-cell" style="font-size:0.8vw;text-align: left"> {{wo.StatusDesc}}, Started
                                {{wo.StartDate |  date:'M/d/yyyy'}}</span>
                        </mat-panel-title>

                        <button mat-button [matMenuTriggerFor]="menu" matTooltip="Menu">
                            <mat-icon style="text-align: left">add</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="addAtWorkorderLevel(wo.EnrollmentId)">Add Workorder</button>
                            <!--  <button mat-menu-item [matMenuTriggerFor]="setup">SetUp</button> -->
                        </mat-menu>
                    </mat-expansion-panel-header>
                    <table *ngIf="wo.Workorders !== undefined && wo.Workorders.length > 0"
                        style="width: 30vw !important; margin-top: 1.2vw !important;" mat-table
                        [dataSource]="wo.Workorders" class="mat-elevation-z8">
                        <ng-container matColumnDef="Name">
                            <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(false)" *matHeaderCellDef style="text-align: left;font-size: 0.8vw">Name</th>
                            <td style="font-size:0.8vw;text-align: left" mat-cell *matCellDef="let workorder">
                                {{workorder.Workorder}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Description">
                            <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(false)" *matHeaderCellDef style="text-align: left;font-size: 0.8vw">Description</th>
                            <td style="font-size:0.8vw;text-align: left" mat-cell *matCellDef="let workorder">
                                {{workorder.ProgramdetailDesc}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="StatusDate">
                            <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(false)" *matHeaderCellDef style="text-align: left;font-size: 0.8vw">Status Date
                            </th>
                            <td style="font-size:0.8vw;text-align: left" mat-cell *matCellDef="let workorder">
                                {{workorder.StatusDate |  date:'M/d/yyyy'}}</td>
                        </ng-container>
                        <ng-container matColumnDef="Status">
                            <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(false)" *matHeaderCellDef style="text-align: left;font-size: 0.8vw">Status</th>
                            <td style="font-size:0.8vw;text-align: left" mat-cell *matCellDef="let workorder">
                                {{workorder.StatusDesc}}&nbsp;<span *ngIf="workorder.Pendhold !== ' '">({{workorder.Pendhold}})</span>
                            </td>
                        </ng-container>

                        <!-- ExpandCollapse Icon Column -->
                        <ng-container matColumnDef="ExpandCollapse">
                            <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(false)" *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button color="primary" matTooltip="Expand">
                                    <mat-icon id="expand_more"  #expand_more *ngIf="!element.close"  >expand_more</mat-icon>
                                    <mat-icon id="expand_less"  #expand_less *ngIf="element.close">expand_less</mat-icon>
                                </button> 
                            </td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="displayedColumns5"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns5;"
                            (dblclick)="handleEnrollmentWorkorderClick(row, wo.EnrollmentId)"
                            [cdkDetailRow]="row" [cdkDetailRowTpl]="tpl"
                            (toggleChange)="onToggleChange($event,row)" class="curr-workorder element-row">
                        </tr>
                    </table>
                    <!--  <mat-paginator #wopaginator [pageSize]="2" showFirstLastButtons [length]="wo.Workorders.length">
                    </mat-paginator> -->
                    <div *ngIf="wo.Workorders === undefined || wo.Workorders.length < 1" style="
                vertical-align: middle;
                text-align: center;
                margin: 0 auto;
                margin-top: 2vw;">No workorders for the current user</div>


                </mat-expansion-panel>


            </mat-accordion>
        </mat-card-content>
        <mat-paginator style="float: right !important" #EnrollPaginator [length]="totalEnrollmentRows$ | async"
            [pageIndex]="0" [pageSize]="3"></mat-paginator>
    </mat-card>

</div>

<ng-template #tpl let-element>
    <!-- <div class="mat-row-assignments" [@detailExpand] > -->
        <!-- <ul *ngFor="let assign of element.Assignments"> -->
            <tr [@detailExpand] *ngFor="let assign of element.Assignments">
                <td colspan="5" style="font-size: 0.75vw;" >
                    <div class="assign-dt">
                        <span class="name-span">{{assign.Status}}</span>
                        <span>{{assign.Assignee}}</span>
                        <span>Start Date: {{assign.Start}}</span>
                    </div>
                </td>
            </tr>
        <!-- </ul> -->
    <!-- </div> -->
</ng-template>