<mat-spinner *ngIf="loadWorkorder" class="spinner"></mat-spinner>
<div *ngIf="!loadWorkorder">

  <mat-card *ngIf="response !== null && !loadWorkorder" [class]="workorderService.getCustomCSSForOfficeId(true)">
    <mat-card-header class="mat-card-header">
      <table class="table-black">
        <thead>
          <tr>
            <td><a href="" (click)="loggedinuserrole != 'DISTRIBUTR' ? customerClick(response.PersoncompanyId) : OnInit()"><span> {{response.PersonCompanyFullname}}
                </span></a></td>
            <td> {{response.ProgramDetailName}} </td>
            <td class="custom-width"> [ {{response.WorkorderId}} - <a href="javascript:void(0);"
                (click)="getWorkOrders(response.WorkorderId)">{{response.Status}}</a> ]</td>
            <td *ngIf="response.PendHold != 'null'" class="blink_me">{{response.PendHold}}</td>
            <td *ngIf="response.ReasonDesc != 'null' && response.ReasonDesc != ' ' " style="color: red;">
              [{{response.ReasonDesc}}]</td>
            <td>
             
           <button *ngIf="response != null && ((response?.SectionInfo[11]?.IsTopSection != 1) && (response?.SectionInfo[12]?.IsTopSection != 1))" mat-button color="primary" (click)="onSave(response)"
              [disabled]="!response.SaveEnabled ||  disableMainSave">
              <mat-icon matTooltip="Save">save</mat-icon>
            </button>

            <button *ngIf="response != null && ((response?.SectionInfo[11]?.IsTopSection == 1 && response?.SectionInfo[12]?.IsTopSection != 1 ) || (response?.SectionInfo[12]?.IsTopSection == 1 && response?.SectionInfo[11]?.IsTopSection != 1)) && response.StatusCd == '250'" mat-button color="primary"
              (click)="openComparisonDialog(response)" [disabled]="!response.SaveEnabled ||  disableMainSave">
              <mat-icon matTooltip="Save">save</mat-icon>
            </button>

            <button *ngIf="response != null && ((response?.SectionInfo[11]?.IsTopSection == 1 && response?.SectionInfo[12]?.IsTopSection != 1) || (response?.SectionInfo[12]?.IsTopSection == 1 && response?.SectionInfo[11]?.IsTopSection != 1)) && response.StatusCd != '250'" mat-button color="primary"
              (click)="onSave(response)" [disabled]="!response.SaveEnabled ||  disableMainSave">
              <mat-icon matTooltip="Save">save</mat-icon>
            </button>

            </td>
            <td>
              <button *ngIf="response != null" mat-button color="warn" (click)="onexception(response.WorkorderId)">
                <mat-icon matTooltip="Exceptions" [ngClass]="{'blink_me1': response.ExceptionsCount >0}"
                  matBadge="{{ response.ExceptionsCount }}" matBadgePosition="after" matBadgeColor="primary">error
                </mat-icon>
              </button>
            </td>

            <td>
              <button *ngIf="response != null" mat-button color="primary" [matMenuTriggerFor]="menu">
                <mat-icon matTooltip="Actions">more</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <div *ngIf="response.Actions.length > 0">
                  <button *ngFor="let item of response.Actions" mat-menu-item
                    (click)="chooseRoute(item.MenuId)">{{item.MenuName}}</button>
                </div>
                <div *ngIf="response.Actions.length <=0">
                  No action items
                </div>
  				<!--    <button mat-menu-item routerLink="/scheduling">Schedule</button>
                <button mat-menu-item (click)="uploadDocs()">Upload Images/Docs</button>
                <button mat-menu-item (click)="viewDocs()">View Images/Docs</button>
                <button mat-menu-item (click)="deactivateWorkorder(response.WorkorderId)">Deactivate</button>
                <button mat-menu-item (click)="cancelWorkorder(response.WorkorderId)">Cancel</button> -->
                <!--  <button mat-menu-item [matMenuTriggerFor]="setup">SetUp</button> -->              
			  </mat-menu>
            </td>
            <td>
              <button matTooltip="Expand/Collapse" mat-button (click)="closeAll()" style="margin-top: 0.5vw">
                <mat-icon style="color: black;" aria-hidden="false" aria-label="collapse">unfold_less
                </mat-icon>
              </button>
            </td>
          </tr>
        </thead>
      </table>
    </mat-card-header>
  </mat-card>
  <!--  <div align="end">
    <button *ngIf="response != null" mat-fab color="primary" (click)="onSave(response)"
      [disabled]="!response.SaveEnabled">
      <mat-icon matTooltip="Save">save</mat-icon>
    </button>
  </div> -->

    <!-- QA QC applicable for Rebate for CL and PS -->
  <div *ngFor="let maintile of response.SectionInfo;let i = index;">
   <div *ngIf="response?.SectionInfo[i]?.IsTopSection == 1" class="forcedQaQc">
      <ng-container *ngFor="let tile of maintile.DetailedInfo[0]?.Attributes">
      <mat-label class="qaQcLabel">{{tile.Name}}</mat-label>
      <mat-checkbox class="qaQCheck" [(ngModel)]="tile.SelectedValue" [disabled]="(tile.IsEditable == 'Yes'? false : true)">
      </mat-checkbox>
    </ng-container>
    <div *ngIf="response.OverallIncentive" class="text-align-right-CL"><b>Total Incentive:
        &#36;{{response.OverallIncentive}}</b></div>
    </div>
  </div>

  <!-- Approve button in IA for CL and PS -->
  <div *ngIf="response.SubGroup1 == 'PREQUAL_CL' || response.SubGroup1 == 'PREQUAL_PS' && response.OverallIncentive" class="text-align-right-CL-IA"><b>Total
      Incentive:
      &#36;{{response.OverallIncentive}}</b> <button *ngIf="response.SubGroup1 == 'PREQUAL_CL' || response.SubGroup1 == 'PREQUAL_PS'"
      (click)="chooseRoute('Complete')" [disabled]="response.ApproveEnabled == 0" class="approve-button-IA" mat-raised-button
      color="primary">Approve</button>
  </div>
  <div class="text-align-right-CL-IA" *ngIf="response.SubGroup1 == 'HOMEAUDIT' && loggedinuserrole == 'ADMIN'">

    <button [disabled]="loggedinuserrole != 'ADMIN' || response?.Approve=='1'" class="approve-button-IA" mat-raised-button color="primary" (click)="chooseRoute('Complete')" >Approve</button>
    <button [disabled]="loggedinuserrole != 'ADMIN' ||  response?.Reject=='1'" class="approve-button-IA"  mat-raised-button color="primary" (click)="chooseRoute('Reject')" >Reject</button>
  </div>
  
  <!-- Approve, Reject button in HVAC Distributor -->
  <div class="text-align-right-CL-IA">
    <button *ngIf="response.DistributorApprove == 1" (click)="chooseRoute('Approve')" class="approve-button-IA"
      mat-raised-button color="primary">Approve</button>
    <button *ngIf="response.DistributorReject == 1" (click)="chooseRoute('Reject')" class="approve-button-IA"
      mat-raised-button color="primary">Reject</button>
  </div>
  <!-- Approve, Reject button in HVAC Distributor -->

  <mat-accordion #Woaccordion="matAccordion" displayMode="flat" multi="true" [togglePosition]="'after'"
    *ngIf="response !== null &&  !loadWorkorder">

    <mat-expansion-panel [expanded]="true" class="expansion-panel"
      *ngFor="let tile of response.SectionInfo;let i = index;">
	   <!-- <div style="display:flex"> -->
      <mat-expansion-panel-header class="mat-header-work-qa"
        *ngIf="tile.SectionName != ' ' && tile?.IsTopSection != 1 && tile?.IsNotesSection != 1">
        <mat-panel-title class="panel-title whitesmoke" [class]="workorderService.getCustomCSSForOfficeId(true)">
          {{tile.SectionName | uppercase }}
        </mat-panel-title>

		<!--

        <button *ngIf="tile.SectionName == 'Manufacturers and Models' "  mat-button >
          <mat-icon  matTooltip="Add Manufacturers" (click)="$event.stopPropagation();addApplianceInfo(i, tile.SectionId)" >add</mat-icon>
        </button>
        -->
        <mat-panel-description>
          <button *ngIf="response.AddApplianceEnabled==1 && tile.SectionId == 'ApplianceInfo'" mat-button
            class="button-mat-desc" (click)="$event.stopPropagation();addApplianceInfo(tile,i)">
            <mat-icon style="margin-bottom: 1vw !important;" color="primary" matTooltip="Add">add</mat-icon>
          </button>

          <button *ngIf="tile.AddApplianceEnabled==1 && tile.Tabular?.length >= 0" mat-button class="button-mat-desc"
            (click)="$event.stopPropagation();addPSGAppliance(i, tile.SectionId)">
            <mat-icon style="margin-bottom: 1vw !important;" color="primary" matTooltip="Add">add</mat-icon>
          </button>
          <button *ngIf="tile.ShowAddInteractions == 1" mat-button
            (click)="$event.stopPropagation();openAddInteraction(response.PersoncompanyId, tile.ShowAddInteractions, tile.SectionName, i)">
            <mat-icon matTooltip="Add Interaction"> add</mat-icon>
          </button>

          <!-------------For Home Audit, Commercial Lighting, Prisciptive Suite------->
          <button *ngIf="tile.ShowHomeAuditMeasures == 1 && response != null" mat-button
            [disabled]="!response.SaveEnabled || tile.ShowHomeAuditMeasures == 0" (click)="$event.stopPropagation(); 
                      openAddMeasure(tile.ShowAddMeasures, i, true)">
            <mat-icon matTooltip="Add Measures"> add</mat-icon>
          </button>
          
          <button *ngIf="tile.ShowAddMeasure == 1 && response != null" mat-button
            [disabled]="!response.SaveEnabled || tile.ShowAddMeasure == 0" (click)="$event.stopPropagation(); 
                                openAddMeasure(tile.ShowAddMeasure, i, tile.Lineitemmasterid)">
            <mat-icon matTooltip="Add {{tile.SectionName}}"> add</mat-icon>
          </button>

        </mat-panel-description>
      </mat-expansion-panel-header>

  	  <!-- </div> -->
      <!-- start of detailed layout -->      
	  <div *ngIf="tile.DetailedInfo?.length > 0 && tile?.IsTopSection != 1 && tile?.IsNotesSection != 1"
        class="accordion-content">
        <div *ngFor="let subTile of tile.DetailedInfo; let d = index " class="sub-container-{{tile.SectionName}}">
          <mat-grid-list cols="12" rowHeight="35px">
            <ng-container *ngFor="let subResponse of subTile.Attributes">
              <mat-grid-tile class="work-get-tile" *ngIf="subResponse.AttributeId != 'TVFD'"
                [ngStyle]="{'text-align': subResponse.DataType=='RadioList' ? 'center' : subResponse.DataType=='Radio' ? 'left' : 'inherit' }"
                colspan="{{subResponse.DataType=='RadioList' || subResponse.DataType=='Radio' ? '12' : '4'}}" [name]="subResponse.AttributeId">
                <mat-label *ngIf="subResponse.DataType=='RadioList'"
                  matTooltip="{{subResponse.Name}}" class="label">{{subResponse.Name}}
                  <span class="symbolStyle" *ngIf="subResponse?.IsMandatory == 1">*</span>
                </mat-label>
                <mat-label *ngIf="subResponse.DataType!='RadioList' && subResponse.DataType!='Radio'"
                  matTooltip="{{subResponse.Name}}" class="label">{{subResponse.Name}}
                  <span class="symbolStyle" *ngIf="subResponse?.IsMandatory == 1">*</span>
                </mat-label>
                <mat-label *ngIf="subResponse.DataType=='Radio'" matTooltip="{{subResponse.Name}}"
                 class="radiolabel">{{subResponse.Name}}
                  <span class="symbolStyle" *ngIf="subResponse?.IsMandatory == 1">*</span>
                </mat-label>

                <mat-form-field appearance="outline" class="work-get-form form-field font"
                  *ngIf="subResponse.DataType=='List' && subResponse.Name != 'Year Built' && subResponse.Name != 'Payee'">
                  <input [name]="subResponse.AttributeId" type="hidden" />
                  <mat-select [(ngModel)]="subResponse.SelectedValue"
                    (ngInit)="oninitialize(subResponse.SelectedValue,subResponse.AttributeId,subResponse.IsEditable)"
                    (ngModelChange)="dataChanged_customize($event,i,d,subResponse.AttributeId)"
                    [disabled]="(subResponse.IsEditable == 'Yes'? false : true)" [name]="subResponse.AttributeId">

                    <mat-option *ngFor="let optionValues of subResponse.Values" [value]="optionValues.Id"
                      class="font-11">
                      {{optionValues.Value}}
                    </mat-option>

                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="work-get-form form-field font"
                  *ngIf="subResponse.DataType=='List' && subResponse.Name != 'Year Built' && subResponse.Name == 'Payee'">
                  <input [name]="subResponse.AttributeId" type="hidden" />
                  <mat-select [(ngModel)]="subResponse.SelectedValue" (selectionChange)="onChangePayee($event)"
                    (ngInit)="oninitialize(subResponse.SelectedValue,subResponse.AttributeId,subResponse.IsEditable)"
                    (ngModelChange)="dataChanged_customize($event,i,d,subResponse.AttributeId);"
                    [disabled]="(subResponse.IsEditable == 'Yes'? false : true)" [name]="subResponse.AttributeId">

                    <mat-option *ngFor="let optionValues of subResponse.Values" [value]="optionValues.Id"
                      class="font-11">
                      {{optionValues.Value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="form-field font"
                  *ngIf="subResponse.DataType=='String' && subResponse.Name == 'Address' ">
                  <ng-container *ngIf="subResponse.Size <=50">
                    <input autocomplete="off" appInputlengthFilter matInput [(ngModel)]="subResponse.SelectedValue"
                      (ngModelChange)="dataChanged($event,i,d)" [disabled]="isDisabled || (subResponse.IsEditable == 'Yes'? false : true)"
                      [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''"
                      [maxlength]="subResponse.Size" [name]="subResponse.AttributeId">
                  </ng-container>
                </mat-form-field>

                <mat-form-field appearance="outline" class="form-field font"
                  *ngIf="subResponse.DataType=='String' && subResponse.Name == 'Address 2'">
                  <ng-container *ngIf="subResponse.Size <=50">
                    <input autocomplete="off" appInputlengthFilter matInput [(ngModel)]="subResponse.SelectedValue"
                      (ngModelChange)="dataChanged($event,i,d)" [disabled]="isDisabled || (subResponse.IsEditable == 'Yes'? false : true)"
                      [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''"
                      [maxlength]="subResponse.Size" [name]="subResponse.AttributeId">
                  </ng-container>
                </mat-form-field>

                <mat-form-field appearance="outline" class="form-field font"
                  *ngIf="subResponse.DataType=='String'  && subResponse.AttributeId == 'pCity'">
                  <ng-container *ngIf="subResponse.Size <=50">
                    <input autocomplete="off" appInputlengthFilter matInput [(ngModel)]="subResponse.SelectedValue"
                      (ngModelChange)="dataChanged($event,i,d)" [disabled]="isDisabled || (subResponse.IsEditable == 'Yes'? false : true)"
                      [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''"
                      [maxlength]="subResponse.Size" [name]="subResponse.AttributeId">
                  </ng-container>
                </mat-form-field>

                <mat-form-field appearance="outline" class="form-field font"
                  *ngIf="subResponse.DataType=='String' && subResponse.AttributeId == 'pState'">
                  <ng-container *ngIf="subResponse.Size <=50">
                    <input autocomplete="off" appInputlengthFilter matInput [(ngModel)]="subResponse.SelectedValue"
                      (ngModelChange)="dataChanged($event,i,d)" [disabled]="isDisabled || (subResponse.IsEditable == 'Yes'? false : true)"
                      [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''"
                      [maxlength]="subResponse.Size" [name]="subResponse.AttributeId">
                  </ng-container>
                </mat-form-field>

                <mat-form-field appearance="outline" class="form-field font"
                  *ngIf="subResponse.DataType=='String' && subResponse.AttributeId == 'pZipcode' ">
                  <ng-container *ngIf="subResponse.Size <=50">
                    <input autocomplete="off" appInputlengthFilter matInput [(ngModel)]="subResponse.SelectedValue"
                      (ngModelChange)="dataChanged($event,i,d)" [disabled]="isDisabled || (subResponse.IsEditable == 'Yes'? false : true)"
                      [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''"
                      [maxlength]="subResponse.Size" [name]="subResponse.AttributeId">
                  </ng-container>
                </mat-form-field>

                <mat-form-field appearance="outline" class="form-field font"
                  *ngIf="subResponse.DataType=='String' && subResponse.Name == 'Payable To'">
                  <ng-container *ngIf="subResponse.Size <=50">
                    <input autocomplete="off" appInputlengthFilter matInput [(ngModel)]="subResponse.SelectedValue"
                      (ngModelChange)="dataChanged($event,i,d)" [disabled]="isDisabled || (subResponse.IsEditable == 'Yes'? false : true)"
                      [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''"
                      [maxlength]="subResponse.Size" [name]="subResponse.AttributeId">
                  </ng-container>
                </mat-form-field>

                <div appearance="outline" class="width-20 font" *ngIf="subResponse.DataType=='RadioList'">
                  <mat-radio-group aria-labelledby="work-get-radio-group-label" class="work-get-radio-group"
                    [(ngModel)]="subResponse.SelectedValue" (ngModelChange)="dataChanged($event,i,d)">
                    <mat-radio-button class="work-get-radio-button" *ngFor="let val of subResponse.Values"
                      [value]="val.Id" [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                      [name]="subResponse.AttributeId">
                      {{val.Value}}</mat-radio-button>
                  </mat-radio-group>
                </div>

                <mat-form-field appearance="outline" class="form-field font" *ngIf="subResponse.DataType=='String' && subResponse.Name != 'Address' && subResponse.Name != 'Address 2' 
                  && subResponse.AttributeId != 'pState' && subResponse.AttributeId != 'Zip' && subResponse.AttributeId != 'pZipcode' && subResponse.AttributeId != 'pCity'
                   && subResponse.Name != 'Payable To'">

                  <ng-container *ngIf="subResponse.Size <=50 && subResponse.Name !='Phone Number'">
                    <input autocomplete="off" appInputlengthFilter matInput [(ngModel)]="subResponse.SelectedValue"
                      (ngModelChange)="dataChanged($event,i,d)" [disabled]="(subResponse.IsEditable == 'Yes' ||
                  (psegdisable == 'Yes' &&
                    tile.SectionName == 'Owner Information' )? false : true)"
                      [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''"
                      [maxlength]="subResponse.Size" [name]="subResponse.AttributeId">
                  </ng-container>
                  <ng-container *ngIf="subResponse.Name =='Phone Number'">
                      <input autocomplete="off" appInputlengthFilter matInput [(ngModel)]="subResponse.SelectedValue"
                        (ngModelChange)="dataChanged($event,i,d)" [disabled]="(subResponse.IsEditable == 'Yes' ||
                                      (psegdisable == 'Yes' &&
                                        tile.SectionName == 'Owner Information' )? false : true)"
                        [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''" [maxlength]="subResponse.Size"
                        [name]="subResponse.AttributeId" mask="(000) 000-0000">
                  </ng-container>
                  <ng-container *ngIf="subResponse.Size >50">
                    <textarea appInputlengthFilter [(ngModel)]="subResponse.SelectedValue" rows="1" cols="15" matInput
                      [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                      [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''"
                      [maxlength]="subResponse.Size" (ngModelChange)="dataChanged($event,i,d)"
                      [name]="subResponse.AttributeId"></textarea>
                  </ng-container>
                </mat-form-field>

                <mat-form-field appearance="outline" class="form-field font" *ngIf="subResponse.DataType=='String' && subResponse.Name != 'Address' && subResponse.Name != 'Address 2' 
                  && subResponse.AttributeId != 'pState' && subResponse.AttributeId == 'Zip' && subResponse.AttributeId != 'pCity'
                   && subResponse.Name != 'Payable To'">

                  <ng-container *ngIf="subResponse.Size <=50">
                    <input autocomplete="off" appInputlengthFilter matInput [(ngModel)]="subResponse.SelectedValue"
                      (ngModelChange)="dataChanged($event,i,d)" [disabled]="(subResponse.IsEditable == 'Yes' ||
                  (psegdisable == 'Yes' &&
                    tile.SectionName == 'Owner Information' )? false : true)"
                      [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''"
                      [maxlength]="subResponse.Size" [name]="subResponse.AttributeId">
                  </ng-container>
                </mat-form-field>

                <mat-form-field appearance="outline" class="form-field font" *ngIf="subResponse.DataType=='Integer'">
                  <input appInputlengthFilter [(ngModel)]="subResponse.SelectedValue" appNumeric matInput min="0"
                    (ngModelChange)="dataChanged($event,i,d)" minlength="0"
                    [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                    [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''" type="number"
                    [max]="subResponse.Max" [name]="subResponse.AttributeId" [maxlength]="subResponse.Size">
                </mat-form-field>

                <mat-form-field appearance="outline" class="form-field font" *ngIf="subResponse.DataType=='Decimal'">
                  <input appInputlengthFilter [(ngModel)]="subResponse.SelectedValue" appDecimalnumber matInput min="0"
                    (ngModelChange)="dataChanged($event,i,d)" minlength="0"
                    [disabled]="(subResponse.IsEditable == 'Yes'? false : true)" type="number" [min]="subResponse.Min" [max]="subResponse.Max"
                    [name]="subResponse.AttributeId" [maxlength]="subResponse.Size">
                </mat-form-field>

                <mat-checkbox style=" width:50% !important;" *ngIf="subResponse.DataType=='Bit'"
                  [disabled]="(subResponse.IsEditable == 'Yes'? false : true)" [(ngModel)]="subResponse.SelectedValue"
                  (ngModelChange)="dataChanged($event,i,d)" [name]="subResponse.AttributeId"
                  [checked]="subResponse.SelectedValue==1?true:false">
                </mat-checkbox>

                <mat-form-field appearance="outline" class="work-get-form form-field font"
                  *ngIf="subResponse.DataType=='Date'">
                  <mat-label></mat-label>
                  <input matInput [matDatepicker]="picker" [(ngModel)]="subResponse.SelectedValue"
                    [readonly]="inputReadOnly"
                    (ngInit)="oninitialize(subResponse.SelectedValue,subResponse.AttributeId,subResponse.IsEditable)"
                    (ngModelChange)="dataChanged_customize($event,i,d,subResponse.AttributeId)"
                    [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                    [name]="subResponse.SelectedValue" [value]="subResponse.SelectedValue">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline" class="work-get-form form-field font"
                  *ngIf="subResponse.DataType=='List' && subResponse.Name == 'Year Built'"
                  (click)="clearYearBuilt($event)">
                  <mat-select [(ngModel)]="subResponse.SelectedValue"
                    (ngInit)="oninitialize(subResponse.SelectedValue,subResponse.AttributeId,subResponse.IsEditable)"
                    (ngModelChange)="dataChanged_customize($event,i,d,subResponse.AttributeId);"
                    [disabled]="(subResponse.IsEditable == 'Yes'? false : true)" [name]="subResponse.AttributeId">
                    <mat-icon class="searchIcon" aria-hidden="false" aria-label="Example search icon"
                      fontIcon="search"></mat-icon>
                    <input class="searchInput" matInput [(ngModel)]="term" placeholder="Search..." appNumeric
                      [maxlength]="subResponse.Size">
                    <mat-option *ngFor="let optionValues of subResponse.Values | filter:term"
                      [value]="optionValues.Value" style="font-size: 11px; ">
                      {{optionValues.Value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div appearance="outline" class="font" *ngIf="subResponse.DataType=='Radio'">
                  <mat-radio-group aria-labelledby="work-get-radio-group-label" class="work-get-radio-group"
                    [(ngModel)]="subResponse.SelectedValue" (ngModelChange)="dataChanged($event,i,d)">
                    <mat-radio-button class="work-get-radio-button" *ngFor="let val of subResponse.Values" [value]="val.Id"
                      [disabled]="(subResponse.IsEditable == 'Yes'? false : true)" [name]="subResponse.AttributeId">
                      {{val.Value}}</mat-radio-button>
                  </mat-radio-group>
                </div>

                  <!--------------Savings------------------>
                <mat-form-field appearance="outline" class="form-field font" *ngIf="subResponse.DataType=='Savings'">
                  <input appInputlengthFilter [(ngModel)]="subResponse.SelectedValue" appSavingsDecimalnumber matInput min="0"
                    (ngModelChange)="dataChanged($event,i,d)" minlength="0" [disabled]="(subResponse.IsEditable == 'Yes'? false : true)"
                    type="number" [min]="subResponse.Min" [max]="subResponse.Max" [name]="subResponse.AttributeId"
                    [maxlength]="subResponse.Size">
                </mat-form-field>

              </mat-grid-tile>
            </ng-container>
            <mat-grid-tile class="align" *ngIf="subTile.ShowDelete == 1">
              <button mat-fab color="warn" (click)="onDeleteAppliance(subTile.AdditionalInfo,response.WorkorderId)">
                <mat-icon matTooltip="Delete">delete</mat-icon>
              </button>
            </mat-grid-tile>
            <!-----------------Import Excel Button------------------>
            <mat-grid-tile *ngIf="response.SubGroup1 == 'LIGHTING' && tile?.ImportEnabled == 1">
              <button mat-button color="primary" class="import-btn-height" (click)="importSalesExcel(response);">Import
              </button>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
      </div>
 	  <!-- end of detailed layout -->

      <mat-spinner *ngIf="loadTable" [diameter]="25" class="spinner">
      </mat-spinner>
      <div>
        <div *ngIf="tile.ShowAddInteractions == 1" [hidden]="!showAddInteractions">
          <mat-card style="width: 98%; height: 105%;">
            <mat-card-content>
              <table class="font">
                <tr>
                  <td>
                    <span style="float: right;">{{ timer }}</span>
                  </td>
                </tr>
              </table>
              <br>
              <table class="font">
                <tr>
                  <td style="width: 250px;padding-right: 10px;">
                    <mat-form-field style="width:240px">
                      <mat-label>Type</mat-label>
                      <mat-select [(ngModel)]="selectedTypeCode" (selectionChange)="changeType($event)">
                        <mat-option *ngFor="let typeobj of types" [value]="typeobj" class="font-11">
                          {{typeobj.value}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td style="width: 250px;">
                    <mat-form-field style="width:240px;padding-right: 12px;">
                      <mat-label>Why</mat-label>
                      <mat-select [(ngModel)]="selectedWhy">
                        <mat-option *ngFor="let why of whys" [value]="why" class="font-11">
                          {{why.value}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field style="width:240px">
                      <mat-label>Marketing</mat-label>
                      <mat-select [(ngModel)]="selectedMarketing">
                        <mat-option *ngFor="let marketObj of marketing" [value]="marketObj" class="font-11">
                          {{marketObj.value}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    <mat-form-field style="width: 90%;">
                      <textarea matInput placeholder="Notes" value="" [(ngModel)]="notesInput"></textarea>
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <mat-checkbox #completeCheckbox (change)="onCompleteClick($event)">Complete
                    </mat-checkbox>
                  </td>
                  <td style="width:100% !important; padding-right:4vw">
                    <mat-form-field style="width:18vw">
                      <mat-label>Reason</mat-label>
                      <mat-select [disabled]="checkIfComplete" [(ngModel)]="selectedReason">
                        <mat-option *ngFor="let reason of reasons" [value]="reason" class="font-11">
                          {{reason.value}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td>
                    &nbsp;
                  </td>
                </tr>
                <tr>
                  <td colspan="3" style="text-align: center">
                    <button mat-button color="primary" class="save-cancel-btn" (click)="addInteraction();"
                      [disabled]="checkGoodDropForm() || adding">Save</button>
                    <button mat-button color="primary" class="save-cancel-btn"
                      (click)="addInteractionClose();">Cancel</button>
                  </td>
                </tr>
                <tr>
                  <td>{{statusMessage}}</td>
                </tr>
              </table>
            </mat-card-content>
          </mat-card>
        </div>
        <table *ngIf="tile.GridValue?.length > 0 && response.SubGroup1 != 'LIGHTING'" class="mat-elevation-z8 table">
          <thead>
            <tr>
              <th class="mat-header-color whitesmoke"
                *ngFor="let head of tile.GridValue[0] |  keyvalue : unsorted" [class]="workorderService.getCustomCSSForOfficeId(false)">{{head.key}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of tile.GridValue" [ngClass]="{'align': tile?.Align == 'center'}">
              <td class="table-data" *ngFor="let list of item | keyvalue : unsorted">
                <mat-icon matTooltip="Check" *ngIf="item[list.key] === 'Complete'">check</mat-icon>
                <span *ngIf="item[list.key] === 'Open'"></span>
                <span *ngIf="item[list.key] !== 'Open' && item[list.key] !== 'Complete'"> {{item[list.key]}}</span>
              </td>
            </tr>
          </tbody>

        </table>

        <!----For Import Table with highlighting errors------>
          <table *ngIf="tile.GridValue?.length > 0 && response.SubGroup1 == 'LIGHTING'" class="mat-elevation-z8 table" id="gridvalue">
            <caption class="caption whitesmoke" [class]="workorderService.getCustomCSSForOfficeId(false)">
              <th>
                MEASURE INFORMATION
              </th>
            </caption>
            <thead>
              <tr class="align">
                <ng-container *ngFor="let head of tile.GridValue[0] |  keyvalue : unsorted">
                  <th class="table-header whitesmoke custom-th" *ngIf="head.key != 'IsMatched'" [class]="workorderService.getCustomCSSForOfficeId(false)">
                    {{head.key}}</th>
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of tile.GridValue">
                <tr [ngClass]="{'high-light': item?.IsMatched == 0}" id="IsMatched">
                  <ng-container *ngFor="let list of item | keyvalue : unsorted">
                    <td class="table-data custom-th" *ngIf="list.key!= 'IsMatched'">
                      <span>{{item[list.key]}}</span>
                    </td>
                  </ng-container>
                </tr>
                <tr *ngIf="item?.IsMatched != 0 && item?.IsMatched != 1">
                  <td class="table-data custom-th" *ngFor="let list of item | keyvalue : unsorted">
                    <span>{{item[list.key]}}</span>
                  </td>
                </tr>
              </ng-container>
            </tbody>
            <tfoot style="display:table; margin-top: 30px;">
            </tfoot>
            <tfoot>
              <tr>
                <td [attr.colspan]="tile.Colspan"><b>Report Total</b></td>
                <td style="text-align: right;"><b>{{response.TotalQuantity ?? 0}}</b></td>
                <td style="text-align: right;"><b>{{"$" + response.TotalCost ?? 0}}</b></td>
              </tr>
            </tfoot>
          </table>

        <table *ngIf="tile.Tabular?.length > 0" class="mat-elevation-z8 table">
          <thead>
			<!-- <ng-container *ngFor="let tableResponse of tile.Tabular.Attributes; let t = index"> -->
            <tr>
              <ng-container *ngFor="let head of tile.Tabular[0].Attributes">
                <!-- <ng-container *ngFor="let colHead of head |  keyvalue : unsorted">  *ngIf="colHead.key == 'Name'" -->
                <th class="table-header-color tabular-header-color" class="whitesmoke">{{head.Name}}</th>
                <!-- </ng-container> -->
              </ng-container>
              <th></th>
            </tr>
            <!-- </ng-container> -->          
			</thead>
          <tbody>
            <ng-container *ngFor="let item of tile.Tabular; let rt = index">
              <tr>
                <ng-container *ngFor="let rowItem of item.Attributes; let rtr = index">
				<!-- <ng-container *ngFor="let list of rowItem | keyvalue : unsorted">  *ngIf="list.key == 'SelectedValue'" -->
                <td class="font">
                    <!--Manufacturers and Models-->                    
					<mat-form-field appearance="outline" class="work-get-form" class="form-field-width-100"
                      *ngIf="rowItem.DataType == 'List'">
                      <mat-label>Select</mat-label>
                      <mat-select [(ngModel)]="rowItem.SelectedValue"
                        (ngModelChange)="psgDataChanged($event,tile.SectionId, rt, rtr, rowItem.Name, item.newIndex)"
                        [disabled]="rowItem.Readonly" [name]="rowItem.AttributeId">
                        <mat-option *ngFor="let optionValues of rowItem.Values" [value]="optionValues.Id"
                          class="font-11">
                          {{optionValues.Value}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="form-field-width-100"
                      *ngIf="rowItem.DataType=='Integer'">
                      <input appInputlengthFilter [(ngModel)]="rowItem.SelectedValue" matInput
                        (ngModelChange)="psgDataChanged($event,tile.SectionId, rt, rtr, rowItem.Name, item.newIndex)"
                        [disabled]="rowItem.Readonly" [value]="rowItem.SelectedValue" type="number"
                        [name]="rowItem.AttributeId">
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="form-field-width-100"
                      *ngIf="rowItem.DataType=='String'">
                      <ng-container *ngIf="rowItem.Size <=50">
                        <input autocomplete="off" appInputlengthFilter matInput [(ngModel)]="rowItem.SelectedValue"
                          (ngModelChange)="psgDataChanged($event,tile.SectionId, rt, rtr, rowItem.Name, item.newIndex)"
                          [disabled]="rowItem.Readonly"
                          [value]="rowItem.SelectedValue != 'null' ? rowItem.SelectedValue: ''"
                          [maxlength]="rowItem.Size" [name]="rowItem.AttributeId">
                      </ng-container>
                      <ng-container *ngIf="rowItem.Size >50">
                        <textarea appInputlengthFilter [(ngModel)]="rowItem.SelectedValue" rows="1" cols="15" matInput
                          [disabled]="rowItem.Readonly"
                          [value]="rowItem.SelectedValue != 'null' ? rowItem.SelectedValue: ''"
                          [maxlength]="rowItem.Size"
                          (ngModelChange)="psgDataChanged($event,tile.SectionId, rt, rtr, rowItem.Name, item.newIndex)"
                          [name]="rowItem.AttributeId"></textarea>
                      </ng-container>
                    </mat-form-field>
					<!-- <span class="tabular-flex" *ngIf="rowItem[list.key] !== 'Open' && rowItem[list.key] !== 'Complete'"> {{list.value}}</span> -->                  
					</td>
                  <!-- </ng-container> -->                
				  </ng-container>
                <td>
                  <button [disabled]="item.DelApplianceEnabled == 0" mat-button
                    (click)="onDeletePSGAppliance(item.LineitemId, rt, item.newIndex, tile.SectionId)">
                    <mat-icon matTooltip="Delete1">delete</mat-icon>
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

      <!-- Measures Code -->
      <div *ngIf="tile.ShowHomeAuditMeasures == 1">
        <app-home-audit-measure [response]="response?.SectionInfo[i]" [workorderResponse]="response"
          (notifyHideMeasures)="openAddMeasure($event)" [measureResponse]="measureResponse" [measuresLength]="measuresLength"
          (notifyLoadWorkorder)="loadDRWorkorder($event)" (saveWorkorder)="onSave(response, $event)">
        </app-home-audit-measure>
      </div>
      
      <app-measures [response]="response?.SectionInfo[i]" [workorderResponse]="response"
        [measureResponse]="measureResponse" [measuresLength]="measuresLength" [myIndex]="i" [currentIndex]="expandedItem"
        [selectedMeasureType]="selectedMeasureType" (selectedTypeNotDUCTHVAC)="openAddMeasure(true,i,$event)"
        (notifyLoadWorkorder)="loadDRWorkorder()" (notifyUploadDoc)="chooseRoute('UploadImage',$event)"
        (notifyViewDoc)="chooseRoute('ViewImage',$event)" (saveWorkorder)="onSave(response, $event)"
        [filterNewFixture]="filterNewFixture" [existingFixtureArray]="existingFixtureArray" [measureData]="measureData"
        [esmodelArray]="esmodelArray" [manufacturerArray]="manufacturerArray" [energyStarModelArray]="energyStarModelArray"
        [manufacturerhvacArray]="manufacturerhvacArray" [modelhvacArray]="modelhvacArray">
      </app-measures>

    </mat-expansion-panel>

  <!-- Work order notes for  -->
  <div *ngFor="let maintile of response.SectionInfo;let i = index;">
   <div *ngIf="response?.SectionInfo[i]?.IsNotesSection == 1">
      <ng-container *ngFor="let tile of maintile?.DetailedInfo[0]?.Attributes">
        <mat-label class="noteLabel">{{tile.Name}}</mat-label>
        <mat-form-field class="font noteTextArea" appearance="outline">
          <textarea [disabled]="(tile.IsEditable == 'Yes'? false : true)" matInput rows="5"  [(ngModel)]="tile.SelectedValue" [maxlength]="tile.Size"></textarea>
        </mat-form-field>
      </ng-container>
    </div>
  </div>

    <button *ngIf="response != null && ((response?.SectionInfo[11]?.IsTopSection != 1) && (response?.SectionInfo[12]?.IsTopSection != 1))" mat-fab color="primary" style="margin-top: 0.5vw;" 
      (click)="onSave(response)" [disabled]="!response.SaveEnabled ||  disableMainSave">
      <mat-icon matTooltip="Save">save</mat-icon>
    </button>

    <button *ngIf="response != null && ((response?.SectionInfo[11]?.IsTopSection == 1 && response?.SectionInfo[12]?.IsTopSection != 1 ) || (response?.SectionInfo[12]?.IsTopSection == 1 && response?.SectionInfo[11]?.IsTopSection != 1)) && response.StatusCd == '250'" mat-fab color="primary" style="margin-top: 0.5vw;"
      (click)="openComparisonDialog(response)" [disabled]="!response.SaveEnabled ||  disableMainSave">
      <mat-icon matTooltip="Save">save</mat-icon>
    </button>

    <button *ngIf="response != null && ((response?.SectionInfo[11]?.IsTopSection == 1 && response?.SectionInfo[12]?.IsTopSection != 1) || (response?.SectionInfo[12]?.IsTopSection == 1 && response?.SectionInfo[11]?.IsTopSection != 1)) && response.StatusCd != '250'" mat-fab color="primary" style="margin-top: 0.5vw;"
      (click)="onSave(response)" [disabled]="!response.SaveEnabled ||  disableMainSave">
      <mat-icon matTooltip="Save">save</mat-icon>
    </button>
    
  </mat-accordion>
</div>