<!-- <mat-spinner *ngIf="searching" style="text-align: center;margin:0 auto; margin-top:5vw"></mat-spinner> -->
<ngx-spinner bdColor = "rgba(128, 128, 128, 0.5)" size = "medium" color = "#ff4081" type = "timer" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>  
<div>
  <mat-card
    style="margin-top: 1vw;margin-right: 1vw; padding-top: 0 !important;font-size:1.1vw;height: 4vw !important;">
    <table style="width:100% !important; color:black">
      <thead>
        <tr>
          <td style="font-size: 0.85vw !important;">
            <a href="" (click)="workorderClick(workorderID)"><span>
                {{workorderID}}
              </span></a>
          </td>
          <td style="font-size: 0.85vw !important;" matTooltip="{{customerAddress}}"><a href=""
              (click)="customerClick(customerId)"><span>
                {{customerName}}
              </span></a></td>
          <td style="width:26% !important">

            <button matTooltip="Left" *ngIf="selectedView === 'day'" mat-button mwlCalendarPreviousView [view]="selectedView"
              (click)="dateChanged($event)" [(viewDate)]="viewDate">
              <mat-icon color="primary">arrow_left</mat-icon>
            </button>
            <mat-form-field appearance="outline"
              style="margin-top:0.2vw;font-size:0.9vw !important;width:50% !important">
              <mat-label>Date</mat-label>
              <input matInput [matDatepicker]="picker" [value]="viewDate" [(ngModel)]="viewDate"
                (dateChange)="dateChanged($event)">
              <mat-datepicker-toggle matSuffix [for]=" picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <button matTooltip="Right" *ngIf="selectedView === 'day'" mat-button mwlCalendarNextView [view]="selectedView"
              (click)="dateChanged($event)" [(viewDate)]="viewDate">
              <mat-icon color="primary">arrow_right</mat-icon>
            </button>
          </td>
          <td>
            <div *ngIf="selectedView === 'week'">
              <mat-form-field appearance="outline" style="font-size: 0.9vw;margin-top: 0.2vw;">
                <mat-label>Resources</mat-label>
                <mat-select [(ngModel)]="selectedUser" (selectionChange)="resourceChanged(e)">
                  <mat-option *ngFor="let user of users" [value]="user.personcompanyid" style="font-size: 11px;">
                    {{user.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div *ngIf="selectedView === 'day'">
              <mat-form-field appearance="outline" style="font-size: 0.9vw;margin-top: 0.2vw;">
                <mat-label>Resources</mat-label>
                <mat-select [(ngModel)]="selectedResources" multiple (selectionChange)="resourceChanged($event)">
                  <mat-option *ngFor="let user of users" [value]="user"
                    [disabled]="selectedResources.length >= 5 && !selectedResources.includes(user)" style="font-size: 11px;">
                    {{user.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </td>
          <td>
            <p style="margin-bottom: 2vw !important">
              <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="selectedView">
                <mat-button-toggle *ngFor="let view of calendarViews" (click)="calendarViewChanged(view.value)"
                  [value]="view.value" [matTooltip]="view.name">
                  <mat-icon color="primary" *ngIf="view.value=='day'">today</mat-icon>
                  <mat-icon color="primary"  *ngIf="view.value=='week'">view_week</mat-icon>
                </mat-button-toggle>
              </mat-button-toggle-group>
            </p>

          </td>
          <td>
            <p style="margin-bottom: 2vw !important">
              <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="selectedInterval.value">
                <mat-button-toggle *ngFor="let interval of timeIntervals" (click)="intervalChanged(interval)"
                  [value]="interval.value" [matTooltip]="interval.name">
                  <mat-icon color="primary" *ngIf="interval.value==30">hourglass_top
                  </mat-icon>
                  <mat-icon color="primary" *ngIf="interval.value==15">av_timer</mat-icon>
                </mat-button-toggle>
              </mat-button-toggle-group>
            </p>
          </td>
          <td>
            <p style="margin-bottom: 2vw !important">
              <mat-button-toggle-group name="fontStyle" aria-label="Font Style" value="Business">
                <mat-button-toggle matTooltip="Full Day" value="FullDay" (click)="hourChanged('full')">
                  <mat-icon color="primary">av_timer</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle matTooltip="Business Hours" value="Business" (click)="hourChanged('business')">
                  <mat-icon color="primary">timelapse</mat-icon>
                </mat-button-toggle>

              </mat-button-toggle-group>
            </p>
          </td>
          <td>
            <p style="margin-bottom: 2vw !important" *ngIf="workorderID != undefined">
              <mat-icon matTooltip="Add Location" style="cursor: pointer;" color="primary" (click)="goToLocationSearch()">
                add_location_alt</mat-icon>

              <mat-icon matTooltip="Map" style="cursor: pointer;padding: 10px;" color="primary" (click)="goToGoogleMap()">
                map</mat-icon>

            </p>
            <p style="margin-bottom: 2vw !important" *ngIf="workorderID == undefined">
              <button matTooltip="Add Loction" mat-button [disabled]="disableLocationSearch" (click)="goToLocationSearch()">
                <mat-icon style="cursor: pointer;" color="primary">
                  add_location_alt</mat-icon>
              </button>
            </p>
          </td>
        </tr>
      </thead>
    </table>
  </mat-card>
  <br>
  <!--    <mat-card style="width: 37%; margin:0 auto;vertical-align: middle;"> -->
  <table *ngIf="workorderID != undefined" style="width: 41%; margin:0 auto;vertical-align: middle;">
    <th colspan="4" style="font-size: 0.8vw; margin:0 auto;vertical-align: middle;text-align: center;">Resource
      Grid/Appointment Status</th>
    <tr>
      <td style="background-color: #f7f59c; font-size: 0.8vw; padding:0.2vw"> Adjacent Grid/Open</td>
      <td style="background-color: #f0ac36; font-size: 0.8vw; padding:0.2vw"> Other Grid/Canvas</td>
      <td style="background-color: #b7fd67; font-size: 0.8vw; padding:0.2vw"> Same Grid/Complete</td>
      <td style="background-color: #FF9F9F; font-size: 0.8vw; padding:0.2vw"> Nearby Grid/Timeoff</td>
    </tr>
  </table>

  <table *ngIf="workorderID == undefined" style="width: 41%; margin:0 auto;vertical-align: middle;">
    <th colspan="4" style="font-size: 0.8vw; margin:0 auto;vertical-align: middle;text-align: center;">Appointment
      Status</th>
    <tr>
      <td style="background-color: #f7f59c; font-size: 0.8vw; padding:0.2vw;text-align: center;">Open</td>
      <td style="background-color: #f0ac36; font-size: 0.8vw; padding:0.2vw;text-align: center;">Canvas</td>
      <td style="background-color: #b7fd67; font-size: 0.8vw; padding:0.2vw;text-align: center;">Complete</td>
      <td style="background-color: #FF9F9F; font-size: 0.8vw; padding:0.2vw;text-align: center;">Timeoff</td>
    </tr>
  </table>
  <!--   </mat-card> -->
  <br>
  <context-menu #basicMenu (open)='checkifPrevSegmentEnabled($event)'>
    <ng-template *ngIf="name=='schedule'" contextMenuItem (execute)="addEvent($event,$event.value)">
      Add Schedule
    </ng-template>
    <ng-template *ngIf="name=='reschedule'" contextMenuItem (execute)="addEvent($event,$event.value)">
      Reschedule
    </ng-template>

    <ng-template *ngIf="(workorderID == undefined && !showMoveContext)" contextMenuItem
      (execute)="moveAllOpenAppt($event,$event.value)">
      Move all Appts
    </ng-template>

   
    

    <ng-template *ngIf="(workorderID == undefined && !showMoveContext)" contextMenuItem
    (execute)="moveSingleOpenAppt($event,$event.value)">
    Move Single Appt
  </ng-template>
  </context-menu>

  <context-menu #eventMenu>
    <ng-template *ngIf="name=='reschedule'" contextMenuItem (execute)="cancelSingleAppt($event,$event.value)">
      Cancel
    </ng-template>
    
    <ng-template *ngIf="workorderID == undefined && showMoveContext" contextMenuItem
      (execute)="selectSingleAppt($event,$event.value)">
      Select Appt
    </ng-template>
     
    
    <ng-template *ngIf="workorderID == undefined && showMoveContext" contextMenuItem
      (execute)="moveAllOpenAppt($event,$event.value)">
      Select All Open Appts
    </ng-template>
    <ng-template *ngIf="workorderID == undefined && showMoveContext" contextMenuItem
      (execute)="cancelAllOpenAppt($event,$event.value)">
      Cancel All Open Appts
    </ng-template>
  </context-menu>



  <ng-template #monthCellTemplate let-day="day" let-openDay="openDay" let-locale="locale"
    let-tooltipPlacement="tooltipPlacement" let-highlightDay="highlightDay" let-unhighlightDay="unhighlightDay"
    let-eventClicked="eventClicked">
    <div class="fill-height" [contextMenu]="basicMenu" [contextMenuValue]="day.date">
      <div class="cal-cell-top">
        <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
        <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
      </div>
      <div class="cal-events">
        <div class="cal-event" *ngFor="let event of day.events" [style.backgroundColor]="event.color.primary"
          [ngClass]="event?.cssClass" (mouseenter)="highlightDay.emit({event: event})"
          (mouseleave)="unhighlightDay.emit({event: event})"
          [mwlCalendarTooltip]="event.title | calendarEventTitle:'monthTooltip':event"
          [tooltipPlacement]="tooltipPlacement" (click)="$event.stopPropagation(); eventClicked.emit({event: event})">
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #weekHeaderTemplate let-days="days" let-locale="locale" let-dayHeaderClicked="dayHeaderClicked">
    <div class="cal-day-headers">
      <div class="cal-header" *ngFor="let day of days" [class.cal-past]="day.isPast" [class.cal-today]="day.isToday"
        [class.cal-future]="day.isFuture" [class.cal-weekend]="day.isWeekend"
        (click)="dayHeaderClicked.emit({day: day})" [contextMenu]="basicMenu" [contextMenuValue]="day">
        <b>{{ day.date | calendarDate:'weekViewColumnHeader':locale }}</b><br />
        <span>{{ day.date | calendarDate:'weekViewColumnSubHeader':locale }}</span>
      </div>
    </div>

  </ng-template>

  <ng-template #dayHourSegmentTemplate let-segment="segment" let-locale="locale" let-segmentHeight="segmentHeight">
    <div class="cal-hour-segment" [style.height.px]="segmentHeight" [class.cal-hour-start]="segment.isStart"
      [class.cal-after-hour-start]="!segment.isStart" [ngClass]="segment.cssClass" [contextMenu]="basicMenu"
      [contextMenuValue]="segment">
      <div class="cal-time">
        {{ segment.date | calendarDate: 'dayViewHour':locale }}
      </div>
    </div>
  </ng-template>

  <ng-template #weekViewHourSegment let-segment="segment" let-locale="locale" let-segmentHeight="segmentHeight"
    let-isTimeLabel="isTimeLabel">
    <div class="cal-hour-segment" [style.height.px]="segmentHeight" [class.cal-hour-start]="segment.isStart"
      [class.cal-after-hour-start]="!segment.isStart" [ngClass]="segment.cssClass" [contextMenu]="basicMenu"
      [contextMenuValue]="segment">
      <div class="cal-time" *ngIf="isTimeLabel">
        {{ segment.date | calendarDate: 'weekViewHour':locale }}
      </div>
    </div>

  </ng-template>

  <ng-template #weekEventTemplate let-weekEvent="weekEvent" let-locale="locale" let-tooltipPlacement="tooltipPlacement"
    let-eventClicked="eventClicked">
    <div class="cal-event" [ngClass]="weekEvent?.cssClass"
      [mwlCalendarTooltip]="weekEvent.event.title | calendarEventTitle:'weekTooltip':weekEvent.event"
      [tooltipPlacement]="tooltipPlacement" (click)="$event.stopPropagation(); eventClicked.emit({ event: weekEvent,
            sourceEvent: $event.sourceEvent})" [contextMenu]="eventMenu" [contextMenuValue]="weekEvent.event">
      <mwl-calendar-event-title [event]="weekEvent.event" view="week"></mwl-calendar-event-title>

      <mwl-calendar-event-actions [event]="weekEvent.event"></mwl-calendar-event-actions>

    </div>
  </ng-template>

  <div *ngIf="loadedData" [ngSwitch]="selectedView" style="overflow: auto !important;">
    <mwl-day-view-scheduler *ngSwitchCase="'day'" [refresh]="refresh" [viewDate]="viewDate" [events]="events"
      [resources]="users" [dayStartHour]="dayStart" [dayEndHour]="dayEnd" [users]="selectedResources"
      [headerTemplate]="weekHeaderTemplate" [hourInterval]=selectedInterval.value
      [hourSegmentTemplate]="weekViewHourSegment" [hourSegments]="selectedInterval.tickValue"
      [customerInfo]="initialInfo" (eventTimesChanged)="eventTimesChanged($event)" [eventTemplate]="weekEventTemplate"
      (userChanged)="userChanged($event)" precision="minutes" (beforeViewRender)="beforeViewRender($event)">
    </mwl-day-view-scheduler>
    <mwl-calendar-week-view *ngSwitchCase="'week'" [refresh]="refresh" [viewDate]="viewDate" [events]="events"
      precision="minutes" [headerTemplate]="weekHeaderTemplate" [hourInterval]=selectedInterval.value
      [hourSegmentTemplate]="weekViewHourSegment" [hourSegments]="selectedInterval.tickValue"
      (eventTimesChanged)="eventTimesChanged($event)" [hourSegmentHeight]="23"
      [hourSegments]="selectedInterval.tickValue" [customerInfo]="initialInfo" [eventTemplate]="weekEventTemplate"
      [dayStartHour]="dayStart" [dayEndHour]="dayEnd" (beforeViewRender)="beforeViewRender($event)"
      (userChanged)="userChanged($event)">
    </mwl-calendar-week-view>
  </div>
</div>
