<mat-card class="user-card" style="width: 95%;">
  <mat-card-header>
    <mat-card-title style="font-size: 16px">
      Individual Maintenance {{ indService.indDetail.IndividualTab.Individual.FULLNAME }}
    </mat-card-title>
    <mat-card-subtitle>
      
      
    </mat-card-subtitle>
    <div class="tp-button">
        <button mat-raised-button color="primary" (click)="saveIndividual()">Save</button>
        <button mat-raised-button color="primary" (click)="reset()" [disabled]="!indService.editMode">Reset</button>
        <button mat-raised-button color="primary" routerLink="/individualmaintenance/1">Back</button>
      </div>
  </mat-card-header>
  <mat-divider></mat-divider>
  <br>
  <mat-card-content>
    <mat-tab-group color="accent" backgroundColor="primary">
      <mat-tab label="Individual">
        <br>
        <div class="center">
          <mat-form-field style="width: 100%;" appearance="outline" class="formfield-half">
            <input matInput placeholder="Name" [(ngModel)]="indService.indDetail.IndividualTab.Individual.FULLNAME" [disabled]="isOrganizationFieldsDisabled">
          </mat-form-field>

          <mat-form-field style="width: 100%;" appearance="outline" class="formfield-half">
            <mat-label>Organization</mat-label>
            <mat-select [(ngModel)]="indService.indDetail.IndividualTab.Individual.SELECTEDORGCODE" [disabled]="isOrganizationFieldsDisabled">
              <mat-option *ngFor="let org of indService.indDetail.IndividualTab.Individual.ORGANIZATIONLIST"
                [value]="org.CODE" style="font-size: 11px;">
                {{org.DESCRIPTION}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <br>
        <table>
            <tr>
                <td width="50%">
          <mat-card style="width: 90%;">
            <mat-card-header>
              <mat-card-title style="font-size: 16px">
                Phone
              </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <br>
            <mat-card-content>
              <table mat-table [dataSource]="dataSourcePhone" class="mat-elevation-z8">
                <ng-container matColumnDef="Type">
                  <th mat-header-cell *matHeaderCellDef>Type</th>
                  <td mat-cell *matCellDef="let phone">
                    {{ phone.SELECTEDPHONETYPEDESCRIPTION }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Number">
                  <th mat-header-cell *matHeaderCellDef>Number</th>
                  <td mat-cell *matCellDef="let phone">
                    ({{ phone.AREACODE }}) {{ phone.EXCHANGE }}-{{ phone.PNUMBER}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="extension">
                  <th mat-header-cell *matHeaderCellDef>Extension</th>
                  <td mat-cell *matCellDef="let phone">
                    {{ phone.EXTENSION}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Edit">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;">Edit</th>
                  <td mat-cell *matCellDef="let phone; let i = index" align="center">
                    <button mat-icon-button color="accent" (click)=editPhone(i) [disabled]="isOrganizationFieldsDisabled" matTooltip="Edit">
                      <mat-icon>edit</mat-icon>
                    </button>
                  </td>
                </ng-container> 
                
                <ng-container matColumnDef="Delete">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let phone; let i = index">
                    <button mat-icon-button color="warn" (click)=deletePhone(i) [disabled]="isOrganizationFieldsDisabled" *ngIf="phone.PHONE_ID == null" matTooltip="Delete">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsPhone"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsPhone;"></tr>
              </table>
              <br>
            </mat-card-content>
            <mat-card-actions>
              <button mat-raised-button color="primary" (click)="addPhone()" [disabled]="isOrganizationFieldsDisabled">Add Phone</button>
            </mat-card-actions>
          </mat-card>
          </td>
          <td>
          <mat-card style="width: 90%;">
            <mat-card-header>
              <mat-card-title style="font-size: 16px">
                Email
              </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <br>
            <mat-card-content>
              <table mat-table [dataSource]="dataSourceEmail" class="mat-elevation-z8">
                <ng-container matColumnDef="Email">
                  <th mat-header-cell *matHeaderCellDef width="75%">Email</th>
                  <td mat-cell *matCellDef="let email">
                    {{ email.EMAILADDRESS  }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Edit">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;">Edit</th>
                  <td mat-cell *matCellDef="let email; let i = index" align="center">
                    <button mat-icon-button color="accent" (click)=editEmail(i) [disabled]="isOrganizationFieldsDisabled" matTooltip="Edit">
                      <mat-icon>edit</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Delete">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    <button mat-icon-button color="warn" (click)=deleteEmail(i) [disabled]="isOrganizationFieldsDisabled" *ngIf="element.EMAIL_ID == null" matTooltip="Delete">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsEmail"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsEmail;"></tr>
              </table>
              <br>
            </mat-card-content>
            <mat-card-actions>
              <button mat-raised-button color="primary" (click)="addEmail()" [disabled]="isOrganizationFieldsDisabled">Add Email</button>
            </mat-card-actions>
          </mat-card>
          </td>
          </tr>
        </table>
        <br>
        <div class="center">
          <mat-card>
            <mat-card-header>
              <mat-card-title style="font-size: 16px">
                Address
              </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <br>
            <mat-card-content>
              <table mat-table [dataSource]="dataSourceAddress" class="mat-elevation-z8">
                <ng-container matColumnDef="Type">
                  <th mat-header-cell *matHeaderCellDef>Type</th>
                  <td mat-cell *matCellDef="let address">
                    {{ address.SELECTEDADDRTYPEDESCRIPTION}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Address">
                  <th mat-header-cell *matHeaderCellDef>Address</th>
                  <td mat-cell *matCellDef="let address">
                    <p>{{ address.STREETNUMBER}} {{ address.STREETNAME }} {{ address.STREETTYPE }}</p>
                    <p>{{ address.CITY }}, {{ address.STATEPROVINCE}} {{ address.ZIP}}</p>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Edit">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;">Edit</th>
                  <td mat-cell *matCellDef="let address; let i = index" align="center">
                    <button mat-icon-button color="accent" (click)=editAddress(i) [disabled]="isOrganizationFieldsDisabled" matTooltip="Edit">
                      <mat-icon>edit</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Delete">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    <button mat-icon-button color="warn" matTooltip="Delete" (click)=deleteAddress(i) [disabled]="isOrganizationFieldsDisabled" *ngIf="element.ADDRESS_ID == null">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsAddress"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsAddress;"></tr>
              </table>
              <br>
            </mat-card-content>
            <mat-card-actions>
              <button mat-raised-button color="primary" (click)="addAddress()" [disabled]="isOrganizationFieldsDisabled">Add Address</button>
            </mat-card-actions>
          </mat-card>
        </div>
      </mat-tab>
      <!-- ------------------- Roles ----------------------------------------------->
      <mat-tab label="Roles">
        <br>
        <div class="center" style="width: 90%;">
          <mat-card>
            <mat-card-header>
              <mat-card-title style="font-size: 16px">
                Roles
              </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <br>
            <mat-card-content>
              <table mat-table [dataSource]="dataSourceRoles" class="mat-elevation-z8">
                <ng-container matColumnDef="Role">
                  <th mat-header-cell *matHeaderCellDef>Role</th>
                  <td mat-cell *matCellDef="let role">
                    {{ role.SELECTEDROLEDESCRIPTION}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="EndDate">
                  <th mat-header-cell *matHeaderCellDef>End Date</th>
                  <td mat-cell *matCellDef="let role">
                    {{ role.LASTDATE | date:'shortDate' }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Inventory">
                  <th mat-header-cell *matHeaderCellDef>Inventory</th>
                  <td mat-cell *matCellDef="let role">
                    <mat-icon matTooltip="Check" *ngIf="role.INVENTORY">check</mat-icon>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Active">
                  <th mat-header-cell *matHeaderCellDef>Active</th>
                  <td mat-cell *matCellDef="let role">
                    <mat-icon matTooltip="Check" *ngIf="role.WORKRESOURCE_ACTIVE">check</mat-icon>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Certification">
                  <th mat-header-cell *matHeaderCellDef>Certification</th>
                  <td mat-cell *matCellDef="let role">
                    <mat-icon matTooltip="Check" *ngIf="role.CERTIFICATION">check</mat-icon>
                  </td>
                </ng-container>
                <ng-container matColumnDef="ViewKeys">
                  <th mat-header-cell *matHeaderCellDef>View Keys</th>
                  <td mat-cell *matCellDef="let role">
                    <button mat-icon-button color="warn" matTooltip="Privacy Tip" (click)=viewClientKeys(role)>
                      <mat-icon>privacy_tip</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Edit">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;">Edit</th>
                  <td mat-cell *matCellDef="let role; let i = index" align="center">
                    <button mat-icon-button color="accent" matTooltip="Edit" (click)=editRoles(i)>
                      <mat-icon>edit</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Delete">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    <button mat-icon-button color="warn" matTooltip="Delete" (click)=deleteRoles(i) *ngIf="element.WORKRESOURCE_ID == null">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsRoles"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsRoles;"></tr>
              </table>
              <br>
            </mat-card-content>
            <mat-card-actions>
              <button mat-raised-button color="primary" (click)="addRoles()">Add Role</button>
            </mat-card-actions>
          </mat-card>
        </div>
      </mat-tab>
      <!-- Grids --------------------------------------------------------------------- -->
      <mat-tab label="Grids">
        <br>
        <div class="center">
          <mat-card>
            <mat-card-header>
              <mat-card-title style="font-size: 16px">
                Grids
              </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <br>
            <mat-card-content>
              <table mat-table [dataSource]="dataSourceGrids" class="mat-elevation-z8">
                <ng-container matColumnDef="Grid">
                  <th mat-header-cell *matHeaderCellDef>Grid</th>
                  <td mat-cell *matCellDef="let grid">
                    {{ grid.SELECTEDGRIDDESCRIPTION}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="EndDate">
                  <th mat-header-cell *matHeaderCellDef>End Date</th>
                  <td mat-cell *matCellDef="let grid">
                    {{ grid.ENDDATE | date:'shortDate' }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Edit">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;">Edit</th>
                  <td mat-cell *matCellDef="let grid; let i = index" align="center">
                    <button mat-icon-button matTooltip="Edit" color="accent" (click)=editGrids(i)>
                      <mat-icon>edit</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Delete">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    <button mat-icon-button matTooltip="Delete" color="warn" (click)=deleteGrids(i) *ngIf="element.RESOURCEGRID_ID == null">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsGrids"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsGrids;"></tr>
              </table>
              <br>
            </mat-card-content>
            <mat-card-actions>
              <button mat-raised-button color="primary" (click)="addGrids()">Add Grid</button>
            </mat-card-actions>
          </mat-card>
        </div>
      </mat-tab>
      <!-- Daily Hours -------------------------------------------------------------------  -->
      <mat-tab label="Daily Hours">
        <br>
        <div class="center">
          <mat-card>
            <mat-card-header>
              <mat-card-title style="font-size: 16px">
                Daily Hours
              </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <br>
            <mat-card-content>
              <table mat-table [dataSource]="dataSourceDailyHours" class="mat-elevation-z8">
                <ng-container matColumnDef="dotw">
                  <th mat-header-cell *matHeaderCellDef>Day of the Week</th>
                  <td mat-cell *matCellDef="let dailyhours">
                    {{ dailyhours.SELECTEDDAYOFWEEKDESC}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Start">
                  <th mat-header-cell *matHeaderCellDef>Start</th>
                  <td mat-cell *matCellDef="let dailyhours">
                    {{ dailyhours.STARTDATE | date:"short" }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="End">
                  <th mat-header-cell *matHeaderCellDef>End</th>
                  <td mat-cell *matCellDef="let dailyhours">
                    {{ dailyhours.ENDDATE | date:"short" }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Edit">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;">Edit</th>
                  <td mat-cell *matCellDef="let role; let i = index" align="center">
                    <button mat-icon-button matTooltip="Edit" color="accent" (click)=editDailyHours(i)>
                      <mat-icon>edit</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Delete">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let dailyhours; let i = index">
                    <button mat-icon-button color="warn" matTooltip="Delete" (click)=deleteDailyHours(i)
                      *ngIf="dailyhours.DAILYHOURS_ID == null">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsDailyHours"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsDailyHours;"></tr>
              </table>
              <br>
            </mat-card-content>
            <mat-card-actions>
              <button mat-raised-button color="primary" (click)="addDailyHours()">Add Hours</button>
            </mat-card-actions>
          </mat-card>
        </div>
      </mat-tab>
      <!--   Time off ------------------------------------------------------------------------------  -->
      <mat-tab label="Time Off">
        <br>
        <div class="center" style="width: 98%;">
          <mat-card>
            <mat-card-header>
              <mat-card-title style="font-size: 16px">
                Time Off
              </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <br>
            <mat-card-content>
              <table mat-table [dataSource]="dataSourceTimeOff" class="mat-elevation-z8">
                <ng-container matColumnDef="Start">
                  <th mat-header-cell *matHeaderCellDef>Start</th>
                  <td mat-cell *matCellDef="let timeoff">
                    {{ timeoff.STARTTIME | date:'short' }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="End">
                  <th mat-header-cell *matHeaderCellDef>End</th>
                  <td mat-cell *matCellDef="let timeoff">
                    {{ timeoff.ENDTIME | date:'short' }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Reason">
                  <th mat-header-cell *matHeaderCellDef>Reason</th>
                  <td mat-cell *matCellDef="let timeoff">
                    {{ timeoff.NOTES}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="User">
                  <th mat-header-cell *matHeaderCellDef>User</th>
                  <td mat-cell *matCellDef="let timeoff">
                    {{ timeoff.USER_ID}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="DateEntered">
                  <th mat-header-cell *matHeaderCellDef>Date Entered</th>
                  <td mat-cell *matCellDef="let timeoff">
                    {{ timeoff.STATUSDATESYS | date:'shortDate' }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Edit">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;">Edit</th>
                  <td mat-cell *matCellDef="let role; let i = index" align="center">
                    <button mat-icon-button matTooltip="Edit" color="accent" (click)=editTimeOff(i)>
                      <mat-icon>edit</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Delete">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let timeoff; let i = index">
                    <button matTooltip="Delete" mat-icon-button color="warn" (click)=deleteTimeOff(i) *ngIf="timeoff.TIMEOFF_ID == null">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsTimeOff"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsTimeOff;"></tr>
              </table>
              <br>
            </mat-card-content>
            <mat-card-actions>
              <button mat-raised-button color="primary" (click)="addTimeOff()">Add Time Off</button>
            </mat-card-actions>
          </mat-card>
        </div>
      </mat-tab>

      <!-- Additional Hours ------------------------------------------------------------------------ -->
      <mat-tab label="Additional Hours">
        <br>
        <div class="center">
          <mat-card>
            <mat-card-header>
              <mat-card-title style="font-size: 16px">
                Additional Hours
              </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <br>
            <mat-card-content>
              <table mat-table [dataSource]="dataSourceAdditionalHours" class="mat-elevation-z8">
                <ng-container matColumnDef="Start">
                  <th mat-header-cell *matHeaderCellDef>Start Time</th>
                  <td mat-cell *matCellDef="let addhours">
                    {{ addhours.STARTTIME | date:'short' }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="End">
                  <th mat-header-cell *matHeaderCellDef>End Time</th>
                  <td mat-cell *matCellDef="let addhours">
                    {{ addhours.ENDTIME | date:'short' }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Edit">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;">Edit</th>
                  <td mat-cell *matCellDef="let role; let i = index" align="center">
                    <button matTooltip="Edit" mat-icon-button color="accent" (click)=editAdditionalHours(i)>
                      <mat-icon>edit</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Delete">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    <button matTooltip="Delete" mat-icon-button color="warn" (click)=deleteAdditionalHours(i)
                      *ngIf="element.ADDITIONALHOURS_ID == null">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsAdditionalHours"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsAdditionalHours;"></tr>
              </table>
              <br>
            </mat-card-content>
            <mat-card-actions>
              <button mat-raised-button color="primary" (click)="addAdditionalHours()">Add Additional Hours</button>
            </mat-card-actions>
          </mat-card>
        </div>
      </mat-tab>

      <!-- Bucket Scheduling ------------------------------------------------------------------------ -->
      <mat-tab label="Bucket Scheduling" *ngIf="isBucketEnabled">
        <br>
        <div class="center" style="width: 95%;">
          <mat-card style="width: 95%;">
            <mat-card-header>
              <mat-card-title style="font-size: 16px">
                Bucket Scheduling
              </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <br>
            <mat-card-content>
              <table mat-table [dataSource]="dataSourceBucketScheduling" class="mat-elevation-z8">
                <ng-container matColumnDef="workorder">
                  <th mat-header-cell *matHeaderCellDef>Workorder</th>
                  <td mat-cell *matCellDef="let bucket">
                    {{ bucket.SELECTEDWOTYPEDESC}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="grid">
                  <th mat-header-cell *matHeaderCellDef>Grid</th>
                  <td mat-cell *matCellDef="let bucket">
                    {{ bucket.SELECTEDGRIDDESC}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="timeslot">
                  <th mat-header-cell *matHeaderCellDef>Time Slot</th>
                  <td mat-cell *matCellDef="let bucket">
                    {{ bucket.TIMESLOT_NAME}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="apptqty">
                  <th mat-header-cell *matHeaderCellDef>Appt Qty</th>
                  <td mat-cell *matCellDef="let bucket">
                    {{ bucket.TIMESLOT_QTY}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="startdate">
                  <th mat-header-cell *matHeaderCellDef>Start</th>
                  <td mat-cell *matCellDef="let bucket">
                    {{ bucket.STARTDATE | date:'shortDate' }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="enddate">
                  <th mat-header-cell *matHeaderCellDef>End</th>
                  <td mat-cell *matCellDef="let bucket">
                    {{ bucket.ENDDATE | date:'shortDate' }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="apptstart">
                  <th mat-header-cell *matHeaderCellDef>Appt Start Time</th>
                  <td mat-cell *matCellDef="let bucket">
                    {{ bucket.APPTSTARTTIME | date:'shortTime' }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="apptend">
                  <th mat-header-cell *matHeaderCellDef>Appt End Time</th>
                  <td mat-cell *matCellDef="let bucket">
                    {{ bucket.APPTENDTIME | date:'shortTime' }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="sun">
                  <th mat-header-cell *matHeaderCellDef>Sun</th>
                  <td mat-cell *matCellDef="let bucket">
                    <mat-icon matTooltip="Check" *ngIf="bucket.SUNDAY">check</mat-icon>
                  </td>
                </ng-container>
                <ng-container matColumnDef="mon">
                  <th mat-header-cell *matHeaderCellDef>Mon</th>
                  <td mat-cell *matCellDef="let bucket">
                    <mat-icon matTooltip="Check" *ngIf="bucket.MONDAY">check</mat-icon>
                  </td>
                </ng-container>
                <ng-container matColumnDef="tues">
                  <th mat-header-cell *matHeaderCellDef>Tues</th>
                  <td mat-cell *matCellDef="let bucket">
                    <mat-icon matTooltip="Check" *ngIf="bucket.TUESDAY">check</mat-icon>
                  </td>
                </ng-container>
                <ng-container matColumnDef="wed">
                  <th mat-header-cell *matHeaderCellDef>Wed</th>
                  <td mat-cell *matCellDef="let bucket">
                    <mat-icon matTooltip="Check" *ngIf="bucket.WEDNESDAY">check</mat-icon>
                  </td>
                </ng-container>
                <ng-container matColumnDef="thur">
                  <th mat-header-cell *matHeaderCellDef>Thur</th>
                  <td mat-cell *matCellDef="let bucket">
                    <mat-icon matTooltip="Check" *ngIf="bucket.THURSDAY">check</mat-icon>
                  </td>
                </ng-container>
                <ng-container matColumnDef="fri">
                  <th mat-header-cell *matHeaderCellDef>Fri</th>
                  <td mat-cell *matCellDef="let bucket">
                    <mat-icon matTooltip="Check" *ngIf="bucket.FRIDAY">check</mat-icon>
                  </td>
                </ng-container>
                <ng-container matColumnDef="sat">
                  <th mat-header-cell *matHeaderCellDef>Sat</th>
                  <td mat-cell *matCellDef="let bucket">
                    <mat-icon matTooltip="Check" *ngIf="bucket.SATURDAY">check</mat-icon>
                  </td>
                </ng-container>
                <ng-container matColumnDef="modifiable">
                  <th mat-header-cell *matHeaderCellDef>Mod</th>
                  <td mat-cell *matCellDef="let bucket">
                    <mat-icon matTooltip="Check" *ngIf="bucket.MODIFIABLE">check</mat-icon>
                  </td>
                </ng-container>
                <ng-container matColumnDef="deactivate">
                  <th mat-header-cell *matHeaderCellDef>Deactivate</th>
                  <td mat-cell *matCellDef="let bucket">
                    <mat-icon matTooltip="Check" *ngIf="bucket.DEACTIVATEROW">check</mat-icon>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Edit">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;">Deactive</th>
                  <td mat-cell *matCellDef="let bucket; let i = index" align="center">
                    <button matTooltip="Toggle" mat-icon-button color="accent" (click)=deactivateBucketScheduling(i) [disabled]="bucket.DEACTIVATEROW == true" *ngIf="bucket.WEBTIMESLOTSETUP_ID != null">
                      <mat-icon *ngIf="bucket.DEACTIVATEROW" matTooltip="Deactivate" color="primary">toggle_off</mat-icon>
                      <mat-icon *ngIf="!bucket.DEACTIVATEROW" matTooltip="Activate">toggle_on</mat-icon>
                    </button>
                    <button matTooltip="Edit" mat-icon-button color="warn" (click)=editBucketScheduling(i) *ngIf="bucket.WEBTIMESLOTSETUP_ID == null">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button matTooltip="Delete" mat-icon-button color="warn" (click)=deleteBucketScheduling(i) *ngIf="bucket.WEBTIMESLOTSETUP_ID == null">
                        <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Delete">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    <button matTooltip="Delete" mat-icon-button color="warn" (click)=deleteBucketScheduling(i) *ngIf="element.WEBTIMESLOTSETUP_ID == null">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsBucketScheduling"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsBucketScheduling;"></tr>
              </table>
              <br>
            </mat-card-content>
            <mat-card-actions>
              <button mat-raised-button color="primary" (click)="addBucketScheduling()">Add Bucket</button>
            </mat-card-actions>
          </mat-card>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
<br>
<br>
