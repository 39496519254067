import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';

import { IndividualService } from '../services/individual.service';
import { DataTransferService } from '../services/data-transfer.service';

@Component({
    selector: 'app-individual-maintenance',
    templateUrl: './individual-maintenance.component.html',
    styleUrls: ['./individual-maintenance.component.scss']
})
export class IndividualMaintenanceComponent implements OnInit {
    dataSource = new MatTableDataSource<any>();
    displayedColumns: string[] = ['FULLNAME'];
    searching = false;
    searching2 = false;
    filterText :any;
    indName:any;

    constructor(
        public individualService: IndividualService,
        private router: Router,
        private route: ActivatedRoute,
        private dataTransfer: DataTransferService
    ) { }

    ngOnInit() {
        // this disables the utility program sponsor select at the top of the page
        this.dataTransfer.myMethod(true);
        // search results are kept moving between this page and detail but we want 
        // to clear them if coming in new from the setup menu
        const toggle = this.route.snapshot.paramMap.get('toggle');
        if (toggle === '0') {
            this.refresh();
        } else {
            this.getOrganizationList();
            this.dataSource.data = this.individualService.indvList;
        }

        this.dataSource.filterPredicate =
        (data: any, filtersJson: string) => {
            const matchFilter = [];
            const filters = JSON.parse(filtersJson);

            filters.forEach(filter => {
                const val = data[filter.id] === null ? '' : data[filter.id];
                matchFilter.push(val.toLowerCase().includes(filter.value.toLowerCase()));
            });
            return matchFilter.every(Boolean);
        };
    }

    refresh() {
        this.indName = '';
        this.individualService.selectedOrg = undefined;
        this.individualService.orgList = [];
        this.individualService.indvList = [];
        this.dataSource.data = this.individualService.indvList;

        this.filterText = null;
        this.dataSource.filter = this.filterText;
        this.getOrganizationList();
    }

    getOrganizationList() {
        if (this.individualService.selectedOrg !== undefined && this.individualService.orgList.length > 0) {
            return;
        }

        this.searching = true;
        this.individualService.getOrganizationList().subscribe(data => {
            this.individualService.orgList = data;
            this.searching = false;
        });
    }

    doSearch() {
        this.searching2 = true;
        let val = '';
        if (this.individualService.selectedOrg !== undefined){
            val = this.individualService.selectedOrg.VALUE;
        }
        this.individualService.indName = this.indName;
        this.individualService.getIndividualsForOrganization(val, this.indName).subscribe(data => {
            this.individualService.indvList = data;
            this.dataSource.data = this.individualService.indvList;
            this.searching2 = false;
        }, err => {
            this.searching2 = false;
        });
    }

    handleClick(row) {
        this.searching2 = true;
        this.individualService.getDetail(row.ORGANIZATION_CD, row.PERSONCOMPANY_ID).subscribe(data => {
            // console.log("data: " + JSON.stringify(data,null, 2));
            this.individualService.indDetail = data;

            console.log("***");
            console.log( this.individualService.indDetail );

            this.individualService.editMode = true;
            this.searching2 = false;

            const enableControls = row.WAREHOUSE_ID?.length > 0 ? 'DISABLE' : 'ENABLE';
            this.router.navigate(['/individualDetail', enableControls]);
        });
    }

    applyFilter(filterValue: string) {

       
        const tableFilters = [];
        tableFilters.push({
            id: 'FULLNAME',
            value: filterValue
        });

        
        this.dataSource.filter = JSON.stringify(tableFilters);
    }

    newIndividual() {
        this.searching = true;
        this.individualService.newDetail(this.individualService.selectedOrg.VALUE).subscribe(data => {
            this.individualService.indDetail = data;
            this.individualService.indDetail.IndividualTab.Individual.FULLNAME = '';
            this.individualService.indDetail.IndividualTab.Individual.SELECTEDORGCODE = this.individualService.selectedOrg.VALUE;
            this.individualService.indDetail.IndividualTab.Individual.SELECTEDORGDESCRIPTION = this.individualService.selectedOrg.DESCRIPTION;

            if (this.individualService.indDetail.IndividualTab.Email === undefined || this.individualService.indDetail.IndividualTab.Email == null) {
                this.individualService.indDetail.IndividualTab.Email = [];
            }
            if (this.individualService.indDetail.IndividualTab.Address === undefined || this.individualService.indDetail.IndividualTab.Address == null) {
                this.individualService.indDetail.IndividualTab.Address = [];
            }
            if (this.individualService.indDetail.DailyHoursTab.DailyHours === undefined || this.individualService.indDetail.DailyHoursTab.DailyHours == null) {
                this.individualService.indDetail.DailyHoursTab.DailyHours = [];
            }
            if (this.individualService.indDetail.GridsTab.Grids === undefined || this.individualService.indDetail.GridsTab.Grids == null) {
                this.individualService.indDetail.GridsTab.Grids = [];
            }
            if (this.individualService.indDetail.RolesTab.Roles === undefined || this.individualService.indDetail.RolesTab.Roles == null) {
                this.individualService.indDetail.RolesTab.Roles = [];
            }
            if (this.individualService.indDetail.IndividualTab.Phone === undefined || this.individualService.indDetail.IndividualTab.Phone == null) {
                this.individualService.indDetail.IndividualTab.Phone = [];
            }
            if (this.individualService.indDetail.BucketSchedulingTab.BucketScheduling === undefined || this.individualService.indDetail.BucketSchedulingTab.BucketScheduling == null) {
                this.individualService.indDetail.BucketSchedulingTab.BucketScheduling = [];
            }

            this.individualService.editMode = false;
            
            this.searching = false;
            this.router.navigate(['/individualDetail', 'ENABLE']);            
        });
    }
}
