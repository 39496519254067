import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ProviderResource, UsageReadings } from 'src/app/models/usage-readings';
import { CustomerService } from 'src/app/services/customer.service';
import { UtilityService } from 'src/app/services/utility.service';
import Swal from 'sweetalert2';
import * as XLSX from "xlsx";


@Component({
  selector: 'app-usage-dialog',
  templateUrl: './usage-dialog.component.html',
  styleUrls: ['./usage-dialog.component.scss']
})
export class UsageDialogComponent implements OnInit {
  file: any;
  extension: string;
  jsonContent: UsageReadings[] = [];
  gasUsage: UsageReadings[] = [];
  electricUsage: UsageReadings[] = [];
  preparedJson: UsageReadings[] = [];
  result: any;
  accountNumber: Number;
  viewUsage: boolean;
  usageType: string;
  spin: boolean;
  providerResource: ProviderResource;
  templateDownload: string;
  basePath: string = "../../assets/" + this.utilityService.currentUtility.OfficeId +"/templateExcels/";
    
  constructor(public dialog: MatDialogRef<UsageDialogComponent>,
   private customerService: CustomerService,
   private utilityService: UtilityService
  ) { 
    this.templateDownload = `${this.basePath}MeterUsage.xlsx`
  }
  
  ngOnInit(): void {
    this.spin = true;
    this.customerService.getProviderResources().subscribe(data => {
      this.spin = false;
      if (data?.length > 0) {
        this.providerResource = data[0];
      }
    });
  }

  handleFiles(e) {
    this.file = e.target.files[0];
    this.extension = this.file.name.split(".").pop();
    let fileReader = new FileReader();
    fileReader.onload = (e: any) => {
      this.result = fileReader.result;
    };
    let objContent = fileReader.readAsDataURL(this.file);
    ///----------------read all columns-----------------
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: "binary", cellDates: true });

      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { dateNF: 'yyyy-mm-dd', raw: false });
      // this.accountNumber = data?.length > 0 ? data[0]['ACCOUNT No. '] : null;
      this.prepareJSON(data);
    };
  }

  prepareJSON(data: any) {
    this.preparedJson = [];
    data.forEach((row, index) => {
      const device = row['Device'] as string;
      const mrDate = new Date(row['MR Date'] as string);
      const consumption = Number(row['Consumption'] as string);
      const meterReading = {
        SerialNo: device,
        ReadDate: new Date(mrDate.getFullYear(), mrDate.getMonth(), mrDate.getDate()),
        Usage: consumption,
        PeriodStart: new Date(),
        Demand: null,
        Amount: null,
        UsageType: null
      } as UsageReadings;

      if (index == data.length - 1) {
        meterReading.PeriodStart =  new Date(mrDate.getFullYear(), mrDate.getMonth(), mrDate.getDate() - 30);
      } else {
        const nextRow = data[index + 1];
        const prevEnddate = new Date(nextRow['MR Date'] as string);

        meterReading.PeriodStart = new Date(prevEnddate.getFullYear(), prevEnddate.getMonth(), prevEnddate.getDate() + 1);
      }

      this.preparedJson.push(meterReading);
    });
  }

  closeDialog() {
    this.dialog.close();
  }

  uploadDoc() {
    if (
      this.extension === "csv" ||
      this.extension === "xlsx" ||
      this.extension === "xls"
    ) {
      const providerResourceId = this.usageType === 'Electric' 
      ? this.providerResource.Electric : this.providerResource.Gas;
      const usageReadings = this.usageType === 'Electric' ? this.electricUsage : this.gasUsage;

      if (usageReadings?.length > 0) {
        Swal.fire({
          text: 'Are you sure you want to overwrite the usage data?',
          icon: "warning",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
        }).then((result) => {
          if (result.value) {
            this.saveUsage(providerResourceId, this.preparedJson);
          }
        });
      } else {
        this.saveUsage(providerResourceId, this.preparedJson);
      } 
     } else {
        Swal.fire({
          text: !this.file ? 'No file chosen' : 'Please upload CSV/ Excel file only',
          icon: 'warning',
          showConfirmButton: false,
          timer: 2000,
        });
      }     
  }

  saveUsage(providerResourceId: string, usageReadings: UsageReadings[]) {
    this.spin = true;
        this.customerService.uploadUsage(this.customerService.currentCustomer.PersonCompanyId, providerResourceId,usageReadings)
        .subscribe(data => {
          this.spin = false;
          if (data) {
            if (!data.Action) {
              this.usageType === 'Electric' ? this.electricUsage = [...usageReadings] : this.gasUsage = [...usageReadings];
              this.jsonContent = [...usageReadings];
              Swal.fire({
                text: data.Message,
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
              });              
            } else {
              Swal.fire({
                text: data.Message,
                icon: 'error',
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
          
          this.file = null;
          this.extension = null;
        });
  }

  showUsage(usageType: string) {
    this.viewUsage = !this.viewUsage;
    this.usageType = usageType;
  
    const endpoints = {
      Electric: this.providerResource.Electric,
      Gas: this.providerResource.Gas
    };
  
    const usageData = {
      Electric: this.electricUsage,
      Gas: this.gasUsage
    };
  
    // If data exists, use it; otherwise, fetch it
    if (usageData[this.usageType]?.length > 0) {
      this.jsonContent = [...usageData[this.usageType]];
    } else {
      this.fetchUsageData(this.usageType, endpoints[this.usageType]);
    }
  }
  
  fetchUsageData(usageType: string, endpoint: string) {
    this.spin = true;  
    this.customerService.getUsage(this.customerService.currentCustomer.PersonCompanyId, endpoint)
      .subscribe(
        data => {
          this.spin = false;
          if (data) {
            if (usageType === 'Electric') {
              this.electricUsage = data;
              this.jsonContent = [...this.electricUsage];
            } else if (usageType === 'Gas') {
              this.gasUsage = data;
              this.jsonContent = [...this.gasUsage];
            }
          }
        });
  }
  
  back() {
    this.viewUsage = !this.viewUsage;
    this.usageType = '';
    this.jsonContent = [];
    this.file = null;
    this.extension = null;
  }
}

