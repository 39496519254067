import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilityService } from '../services/utility.service';
import { EnrollmentService } from '../services/enrollment.service';
import { WorkorderService } from '../services/workorder.service';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import Swal from "sweetalert2";
import { FormControl } from '@angular/forms';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'app-dashobaord',
  templateUrl: './dashobaord.component.html',
  styleUrls: ['./dashobaord.component.scss']
})
export class DashobaordComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  workorders: any = [];
  public dataMatrix: any = [];
  buckets: any = [];
  displayedColumns: string[] = ['WorkorderID', 'WorkorderType', 'AccountHolder', 'Distributor', 'Contractor', 'WorkorderStatus', 'Updated', 'Details'];
  dataSource = new MatTableDataSource<any>();

  public displayedColumnsMatrix: string[] = ['New', 'Ready to Schedule', 'Assigned', 'Scheduled', 'Install Complete', 'Data Entry', 'QC Selected',
    'QA SELECTED (ON SITE)', 'Ready for Rebate', 'Processing Rebate', 'Payment In Process', 'Complete', 'Cancel', 'WorkorderType'];
  dataSourceMatrix = new MatTableDataSource<any>();

  workorderIDFilter = new FormControl();
  workorderTypeFilter = new FormControl();
  accountHolderFilter = new FormControl();
  distributorFilter = new FormControl();
  contractorFilter = new FormControl();
  workorderStatusFilter = new FormControl();
  globalFilter = '';

  filteredValues = {
    WorkorderID: '', WorkorderType: '', AccountHolder: '',
    Distributor: '', Contractor: '', WorkorderStatus: ''
  };

  constructor(
    public utilityService: UtilityService,
    private spinner: NgxSpinnerService,
    private workorderService: WorkorderService,
    private enrollmentService: EnrollmentService,
    private router: Router,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    this.spinner.hide();
    this.getWorkOrders();

    this.workorderIDFilter.valueChanges.subscribe((workorderIDFilterValue) => {
      this.filteredValues['WorkorderID'] = workorderIDFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.workorderTypeFilter.valueChanges.subscribe((workorderTypeFilter) => {
      this.filteredValues['WorkorderType'] = workorderTypeFilter;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.accountHolderFilter.valueChanges.subscribe((accountHolderFilter) => {
      this.filteredValues['AccountHolder'] = accountHolderFilter;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.distributorFilter.valueChanges.subscribe((distributorFilter) => {
      this.filteredValues['Distributor'] = distributorFilter;
      this.updateDataSource(this.dataSource.data, this.filteredValues);
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.contractorFilter.valueChanges.subscribe((contractorFilter) => {
      this.filteredValues['Contractor'] = contractorFilter;
      this.updateDataSource(this.dataSource.data, this.filteredValues);
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.workorderStatusFilter.valueChanges.subscribe((workorderStatusFilter) => {
      this.filteredValues['WorkorderStatus'] = workorderStatusFilter;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.dataSource.filterPredicate = this.customFilterPredicate();
  }

  updateDataSource(data: any[], filteredValues: any) {
    data.forEach(element => {
      for (const key in element) {
        if (!element[key] || element[key] === null) {
          element[key] = '';
        }
      }
    });
    this.dataSource.filter = JSON.stringify(filteredValues);
  }

  customFilterPredicate() {
    const myFilterPredicate = (data: any, filter: string): boolean => {
      var globalMatch = !this.globalFilter;
      if (this.globalFilter) {
        globalMatch = data.WorkorderStatus.toString().trim().toLowerCase() === this.globalFilter;
      }
      if (!globalMatch) {
        return;
      }
      let searchString = JSON.parse(filter);
      if (searchString.WorkorderType != "" && searchString.WorkorderStatus != "" &&
        searchString.WorkorderID === "" && searchString.AccountHolder === "" &&
        searchString.Distributor === "" && searchString.Contractor === "") {
        return data.WorkorderType?.toString().trim().toLowerCase() === searchString.WorkorderType?.toLowerCase() &&
          data.WorkorderStatus?.toString().trim().toLowerCase() === searchString.WorkorderStatus?.toLowerCase();
      }
      else if (searchString.WorkorderType != "" && searchString.WorkorderStatus == "" &&
        searchString.WorkorderID === "" && searchString.AccountHolder === "" &&
        searchString.Distributor === "" && searchString.Contractor === "") {
        return data.WorkorderType?.toString().trim().toLowerCase() === searchString.WorkorderType?.toLowerCase();
      }
      else if (searchString.WorkorderStatus === "Complete" || searchString.WorkorderStatus === "Install Complete") {
        return data.WorkorderStatus?.toString().trim().toLowerCase() === searchString.WorkorderStatus?.toLowerCase();
      }
      else {
        return data.WorkorderID.toString().trim().indexOf(searchString.WorkorderID) !== -1 &&
          data.WorkorderType?.toString().trim().toLowerCase().indexOf(searchString.WorkorderType?.toLowerCase()) !== -1 &&
          data.AccountHolder?.toString().trim().toLowerCase().indexOf(searchString.AccountHolder?.toLowerCase()) !== -1 &&
          data.Distributor?.toString().trim().toLowerCase().indexOf(searchString.Distributor?.toLowerCase()) !== -1 &&
          data.Contractor?.toString().trim().toLowerCase().indexOf(searchString.Contractor?.toLowerCase()) !== -1 &&
          data.WorkorderStatus?.toString().trim().toLowerCase().indexOf(searchString.WorkorderStatus?.toLowerCase()) !== -1;
      }
    }
    return myFilterPredicate;
  }

  refrehPage() {
    this.router.navigateByUrl('/dashboard', { skipLocationChange: false })
      .then(() => this.router.navigate(['/dashboard']));
  }

  private getWorkOrders() {
    this.spinner.show();
    this.userService.getDashboardBuckets(this.utilityService.currentUtility.OfficeId).subscribe((data) => {
      if (this.userService.orgCd != null) {
        this.buckets = data["Buckets"];
        this.workorders = data["DATA"];
        this.dataSource.data = this.workorders;
        this.spinner.hide();
        this.getMatrix();
      }
      else {
        this.spinner.hide();
        Swal.fire({
          text: data["Message"],
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    });
  }

  applyFilter(filterValue, value: any = null) {
    if (filterValue?.WorkorderType) {
      this.filteredValues.WorkorderType = filterValue.WorkorderType;
      this.filteredValues.WorkorderStatus = value;
    }
    else if (filterValue) {
      this.filteredValues.WorkorderStatus = filterValue;
    } else {
      this.globalFilter = filterValue;
    }
    this.dataSource.filter = JSON.stringify(this.filteredValues);
  }

  clearColumn(columnKey: string): void {
    columnKey == 'workorderIDFilter' ? this.workorderIDFilter.reset() :
      columnKey == 'workorderTypeFilter' ? this.workorderTypeFilter.reset() :
        columnKey == 'accountHolderFilter' ? this.accountHolderFilter.reset() :
          columnKey == 'distributorFilter' ? this.distributorFilter.reset() :
            columnKey == 'contractorFilter' ? this.contractorFilter.reset() :
              columnKey == 'workorderStatusFilter' ? this.workorderStatusFilter.reset() : "";
    this.refrehPage();
  }

  handleEnrollmentWorkorderClick(data, enrollmentId) {
    const body = { Id: data.WorkorderID };
    this.workorderService.currentWorkorderID = data.WorkorderID;
    this.enrollmentService.currentEnrollmentID = enrollmentId;
    this.router.navigate(['/workorderGetSave', data.Group, data.SubGroup, data.WorkorderID, data?.SubGroup1 ?? "ALL"]);
  }

  getMatrix() {
    this.spinner.show();
    this.userService.getAdminMatrix(this.utilityService.currentUtility.OfficeId).subscribe((data) => {
      this.dataMatrix = data["DATA"];
      this.dataSourceMatrix.data = this.dataMatrix;
      this.spinner.hide();
    });
  }

  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };
}
